import type {EntityListColumn} from 'components';
import {
	renderArrayField,
	renderBoolean,
	renderDate,
	renderRequirementStatus,
} from 'components/EntityList/ColumnRenderers';
import {formatDateTime} from 'i18n/localeDateFormat';
import i18next from 'i18next';
import omitDeep from 'omit-deep-lodash';

export const mapToRef = (
	items: {id: string}[],
	defaultToUndefined?: boolean,
) => {
	const defaultValue = defaultToUndefined ? undefined : [];

	return (
		items?.map(item => ({
			id: item.id,
		})) || defaultValue
	);
};

interface ObjectWithId {
	id: string;
}

export type PossibleRef = undefined | ObjectWithId;

export const convertToRef = (item?: ObjectWithId | null): PossibleRef => {
	if (!item) {
		return undefined;
	}

	return {id: item.id};
};

export function omitTypename<T extends object>(value: T) {
	return omitDeep(value, '__typename') as T;
}

export const mapValues = (obj: any, fn: (val: any) => any) =>
	Object.fromEntries(Object.entries(obj).map(([key, val]) => [key, fn(val)]));

export const groupBy = (
	arr: any[],
	criteria: string | ((item: any) => string),
) => {
	const {hasOwnProperty} = Object.prototype;

	return arr.reduce(function (obj: any, item) {
		const key =
			typeof criteria === 'function' ? criteria(item) : item[criteria];

		if (!hasOwnProperty.call(obj, key)) {
			obj[key] = [];
		}

		obj[key].push(item);

		return obj;
	}, {});
};

export function truncateText(text: string, maxLength: number) {
	if (text.length <= maxLength) {
		return text;
	}

	return text.slice(0, maxLength) + '...';
}

export const getDateColumn = (
	key: string,
	name: string,
	fieldName: string,
	filterable: boolean,
	primitiveFilter?: boolean,
	minWidth?: number | undefined,
): EntityListColumn => ({
	key,
	name,
	fieldName,
	isMultiline: true,
	minWidth: minWidth ?? 100,
	maxWidth: 150,
	isResizable: true,
	filterable,
	primitiveFilter,
	getFilterKey: date => `${date}`,
	getFilterLabel: date => formatDateTime(new Date(date), i18next),
	onRender: renderDate(),
});

export const getTextColumn = (
	key: string,
	name: string,
	fieldName: string,
	isMultiline: boolean,
	filterable: boolean,
) => ({
	key,
	name,
	fieldName,
	isMultiline,
	minWidth: 100,
	maxWidth: 150,
	isResizable: true,
	sortable: true,
	filterable,
});

export const getArrayFieldColumn = (
	key: string,
	name: string,
	fieldName: string,
	filterable: boolean,
) => ({
	key,
	name,
	fieldName,
	isMultiline: true,
	minWidth: 150,
	maxWidth: 200,
	isResizable: true,
	sortable: true,
	filterable,
	onRender: renderArrayField(),
});

export const getBooleanColumn = (
	key: string,
	name: string,
	fieldName: string,
) => ({
	key,
	name,
	fieldName,
	minWidth: 100,
	maxWidth: 150,
	isResizable: true,
	onRender: renderBoolean(),
});

export const getRequirementStatusColumn = (
	key: string,
	name: string,
	fieldName: string,
	filterable: boolean,
) => ({
	key,
	name,
	fieldName,
	minWidth: 150,
	maxWidth: 200,
	isResizable: true,
	sortable: true,
	filterable,
	onRender: renderRequirementStatus(),
});

export const getSideNavigationNode = (
	name: string,
	url: string,
	key: string,
	icon: string,
) => ({
	name,
	url,
	key,
	icon,
});
