/* eslint-disable react/display-name */
import {
	DetailsList,
	IColumn,
	IconButton,
	Panel,
	PanelType,
	PersonaSize,
	SelectionMode,
	Text,
	TooltipHost,
	useTheme,
} from '@fluentui/react';
import {useBoolean} from '@fluentui/react-hooks';
import {
	RenderFn,
	renderBoolean,
	renderDate,
	renderPersona,
	renderRegulatoryDocumentStatus,
	renderRequirementStatus,
	renderRequirementCategory,
	renderRichtext,
	renderStatus,
} from 'components/EntityList/ColumnRenderers';
import {LoadWrapper} from 'components/LoadWrapper';
import {
	ProviderThatEnablesGettingTooltipsFromContext,
	createTooltipTranslationProviderFromNamespace,
} from 'features/localizedTooltips';
import {getRendererForHeaderWithTooltips} from 'features/localizedTooltips/componentsWithTooltips/HeaderWithTooltipsAndStickySupport';
import {DateTimeFormat, formatDateTime} from 'i18n/localeDateFormat';
import _ from 'lodash';
import React, {useContext} from 'react';
import {UseTranslationResponse, useTranslation} from 'react-i18next';
import {
	AuditLog,
	AuditLogChange,
	EntityOperation,
	RegulatoryDocumentStatus,
	WorkflowStatus,
} from 'types';
import {ChangeType} from 'types/auditLogs';
import {
	AuditLogsLoadingInfo,
	AuditLogsLoadingStatusContext,
} from './AuditLogsLoadingStatusProvider';
import {useGetNamedEntityValuesLazyQuery} from './hooks/useGetNamedEntityValues.generated';

const CHANGE_HISTORY_TRANSLATION_NAMESPACE = 'components/changehistory';

const ChangeHistoryTooltipTranslationProvider =
	createTooltipTranslationProviderFromNamespace(
		CHANGE_HISTORY_TRANSLATION_NAMESPACE,
	);

export interface ChangeHistoryProps {
	data: AuditLog[];
	auditFieldName: string;
	renderOldValue?: boolean;
}

export const ChangeHistory: React.FC<ChangeHistoryProps> = ({
	data,
	auditFieldName,
	renderOldValue = false,
}) => {
	const {t} = useTranslation(CHANGE_HISTORY_TRANSLATION_NAMESPACE);
	const theme = useTheme();
	const [isOpen, {setTrue: openPanel, setFalse: dismissPanel}] =
		useBoolean(false);

	const auditLogsLoadingInfo: AuditLogsLoadingInfo = useContext(
		AuditLogsLoadingStatusContext,
	);

	const items = React.useMemo(
		() =>
			data?.filter((a: AuditLog) =>
				auditFieldName === 'derivative'
					? a?.change?.path?.endsWith('derivative')
					: a?.change?.path?.includes(auditFieldName),
			),
		[data, auditFieldName],
	);

	const nodeIds: any = React.useMemo(
		() =>
			items
				?.filter(i => i?.change?.nodeId !== null)
				.map(fi => fi?.change?.nodeId),
		[items],
	);

	const [
		loadValues,
		{data: entityNames, loading: areNamedEntityValuesLoading},
	] = useGetNamedEntityValuesLazyQuery();

	const values = React.useMemo(() => entityNames?.nodes, [entityNames]);

	const columns: IColumn[] = React.useMemo(
		() => [
			{
				key: 'createdAt',
				name: t('CreatedAt'),
				fieldName: 'createdAt',
				minWidth: 100,
				maxWidth: 150,
				isResizable: true,
				onRender: renderDate(DateTimeFormat.DateTimeMonth),
			},
			{
				key: 'createdBy',
				name: t('CreatedBy'),
				fieldName: 'createdBy',
				minWidth: 100,
				maxWidth: 150,
				isResizable: true,
				onRender: renderPersona({size: PersonaSize.size8}),
			},
			{
				key: 'type',
				name: t('Type'),
				fieldName: 'type',
				minWidth: 100,
				maxWidth: 150,
				isResizable: true,
				multiline: true,
				onRender: renderAuditType(),
			},
			...(renderOldValue
				? [
						{
							key: 'oldValue',
							name: t('OldValue'),
							fieldName: 'oldValue',
							minWidth: 100,
							maxWidth: 150,
							isResizable: true,
							isMultiline: true,
							onRender: renderAuditOldValue(auditFieldName, values),
						},
				  ]
				: []),
			{
				key: 'value',
				name: t('Value'),
				fieldName: 'value',
				minWidth: 100,
				maxWidth: 150,
				isResizable: true,
				isMultiline: true,
				onRender: renderAuditValue(auditFieldName, values),
			},
		],
		[values, auditFieldName],
	);

	const getIfIsLoading = (): boolean => {
		const areAuditLogsLoading: boolean = auditLogsLoadingInfo ?? false;
		return areAuditLogsLoading || areNamedEntityValuesLoading;
	};

	return (
		<>
			<TooltipHost content={t('Log')}>
				<IconButton
					iconProps={{iconName: 'History'}}
					onClick={() => {
						if (nodeIds !== undefined)
							if (nodeIds?.length > 0) {
								loadValues({
									variables: {
										ids: nodeIds,
									},
								});
							}

						openPanel();
					}}
					styles={{
						root: {
							height: 28,
							width: 28,
						},
						icon: {
							color: theme.palette.neutralSecondaryAlt,
						},
					}}
				/>
			</TooltipHost>
			<Panel
				headerText={t('Log')}
				isLightDismiss={true}
				onDismiss={dismissPanel}
				type={PanelType.largeFixed}
				isOpen={isOpen}
			>
				<LoadWrapper
					loading={getIfIsLoading()}
					applyVerticalTransformation={false}
				>
					<ChangeHistoryTooltipTranslationProvider>
						<ProviderThatEnablesGettingTooltipsFromContext>
							<DetailsList
								items={items ?? []}
								columns={columns}
								selectionMode={SelectionMode.none}
								onRenderDetailsHeader={getRendererForHeaderWithTooltips(false)}
							/>
						</ProviderThatEnablesGettingTooltipsFromContext>
					</ChangeHistoryTooltipTranslationProvider>
				</LoadWrapper>
			</Panel>
		</>
	);
};

const getIfChangeShouldBeAddition = ({
	entityOperation,
	change,
}: AuditLog): boolean => {
	return entityOperation === EntityOperation.Create && change.type === 'UPDATE';
};

const getChangeType = (auditLog: AuditLog): ChangeType => {
	const shouldBeAddition: boolean = getIfChangeShouldBeAddition(auditLog);
	if (shouldBeAddition) return ChangeType.Add;

	// If both value & oldValue are null, the element should be delete
	if (
		(auditLog.change.value === undefined || auditLog.change.value === null) &&
		(auditLog.change.oldValue === undefined ||
			auditLog.change.oldValue === null)
	) {
		return ChangeType.Remove;
	}

	return auditLog.change.type as ChangeType;
};

const renderAuditType: () => RenderFn = () => (item: AuditLog) => {
	const {t} = useTranslation(CHANGE_HISTORY_TRANSLATION_NAMESPACE);
	const changeType: ChangeType = getChangeType(item);
	return <Text>{t(changeType)}</Text>;
};

type Item = any;

type Statuses = string[];

type AuditLogNewOrOldValue =
	| AuditLogChange['value']
	| AuditLogChange['oldValue'];

interface FieldsToRenderCommonAuditValue<Value = AuditLogNewOrOldValue> {
	fieldName: string;
	/**
	 * We accept this separately instead of using log.change.value because we
	 * might render the old value instead.
	 */
	value: Value;
	log: AuditLog;
	col: IColumn;
}

interface FieldsToGetStatusRenderer {
	/**
	 * Since the value will be treated as an index, make sure to define all
	 * necessary values here. For example, if the value ranges from 0-1, you would
	 * define all 2 values here.
	 */
	statuses: Statuses;
	/**
	 * We must wrap the renderer with a function. Otherwise, it will not be
	 * defined since we are using circular imports.
	 */
	getFieldRenderer: () => RenderFn;
}

type StatusValue = number | null;

/**
 * You can use this when the value is a number that represents a value in an
 * enumerator. For example, the reg doc's status is a number. Then, you can use
 * this function to convert the number into a word. This is useful if you want
 * to use this along @see renderStatus.
 */
const getRenderStatusFromStatuses = ({
	statuses,
	getFieldRenderer,
}: FieldsToGetStatusRenderer) => {
	return ({
		col,
		value: possibleIndex,
		fieldName,
	}: FieldsToRenderCommonAuditValue<StatusValue>): JSX.Element => {
		if (possibleIndex === null) return <></>;
		const status: Statuses[number] | undefined = statuses[possibleIndex];
		const item: Item = {[fieldName]: status};
		return getFieldRenderer()(item, undefined, col);
	};
};

const renderWorkflowStatusFromValueAsIndex = getRenderStatusFromStatuses({
	statuses: [
		WorkflowStatus.New,
		WorkflowStatus.QualityControlInternal,
		WorkflowStatus.InProgressInternal,
		WorkflowStatus.InProgressExternal,
		WorkflowStatus.Clearing,
		WorkflowStatus.Examination,
		WorkflowStatus.Finalized,
		WorkflowStatus.Archived,
		WorkflowStatus.QualityControlExternal,
		WorkflowStatus.ClearingWithReservations,
	],
	getFieldRenderer: () => renderStatus('WorkflowStatus'),
});

const renderRegDocStatusFromValueAsIndex = getRenderStatusFromStatuses({
	statuses: [
		RegulatoryDocumentStatus.Consideration,
		RegulatoryDocumentStatus.Draft,
		RegulatoryDocumentStatus.Final,
		RegulatoryDocumentStatus.Interpretation,
		RegulatoryDocumentStatus.Discarded,
	],
	getFieldRenderer: renderRegulatoryDocumentStatus,
});

const createColumn = (fieldName: string): IColumn => {
	return {fieldName} as IColumn;
};

const renderText = (
	value: FieldsToRenderCommonAuditValue['value'],
): JSX.Element => {
	return <Text>{value}</Text>;
};

interface InfoToRenderFields {
	shouldRender: boolean;
	renderField: (fields: FieldsToRenderCommonAuditValue) => JSX.Element;
}

const getIfIsStatusField = (path: AuditLogChange['path']): boolean => {
	return path.startsWith('$.status');
};

const getIfIsCategoryField = (path: AuditLogChange['path']): boolean => {
	return path.startsWith('$.category');
};

const getInfoToRenderRegDocFields = ({
	log,
}: FieldsToRenderCommonAuditValue): InfoToRenderFields[] => {
	const {path} = log.change;
	const workflow: InfoToRenderFields = {
		shouldRender: path.includes('workflow.status'),
		renderField: renderWorkflowStatusFromValueAsIndex,
	};
	const status: InfoToRenderFields = {
		shouldRender: getIfIsStatusField(path),
		renderField: renderRegDocStatusFromValueAsIndex,
	};
	return [workflow, status];
};

type PossibleInfoToRender = InfoToRenderFields | undefined;

const findInfoToRenderRegDocValue = (
	fields: FieldsToRenderCommonAuditValue,
): PossibleInfoToRender => {
	const infoList: InfoToRenderFields[] = getInfoToRenderRegDocFields(fields);
	return _.find(infoList, {shouldRender: true});
};

const renderCommonRegDocAuditValues = (
	fields: FieldsToRenderCommonAuditValue,
): JSX.Element => {
	const infoToRender: PossibleInfoToRender =
		findInfoToRenderRegDocValue(fields);
	if (infoToRender) return infoToRender.renderField(fields);
	return renderText(fields.value);
};

/**
 * Note that we are dividing each entity type's renderers into different
 * functions because it's likely we'll move them into the corresponding entity
 * type's folders in the future.
 */
const renderCommonAuditValues = (
	fields: FieldsToRenderCommonAuditValue,
): JSX.Element => {
	const isRegDoc: boolean = fields.log.entityType === 'RegulatoryDocument';
	if (!isRegDoc) renderText(fields.value);
	return renderCommonRegDocAuditValues(fields);
};

const renderAuditValueAsDate = (
	value: AuditLogChange['value'],
	i18n: UseTranslationResponse<'translation', undefined>['i18n'],
): JSX.Element => {
	if (!value) return <></>;

	if (value.$date && value.$date.$numberLong) {
		value = value.$date.$numberLong;
	}

	const time: number = parseInt(value, 10);
	const date = new Date(time);
	return <span>{formatDateTime(date, i18n)}</span>;
};

const renderAuditValue: (fieldName: string, values?: any) => RenderFn =
	// eslint-disable-next-line complexity
	(fieldName, values) => (log: AuditLog) => {
		const {i18n} = useTranslation();
		const {change, entityType} = log;

		const {path, value, type, nodeId} = change;

		const item: Item = {};
		item[fieldName] = value;

		const col = createColumn(fieldName);

		if (path.includes('derivativeKind')) {
			return (
				<span>
					{parseInt(value, 10) === 1 ? 'Neuer Typ' : 'Neues Fahrzeug'}
				</span>
			);
		}

		if (value !== undefined && value !== null) {
			if (value.$date !== undefined && value.$date !== null) {
				if (value.$date.$numberLong !== undefined) {
					return renderAuditValueAsDate(value.$date.$numberLong, i18n);
				}
			}
		}

		if (path.includes('date')) return renderAuditValueAsDate(value, i18n);

		if (path.includes('Vsi') && (type === 'UPDATE' || type === 'ADD')) {
			return <span>{value}</span>;
		}

		if (path.startsWith('$.summary') || path.startsWith('$.definition')) {
			return renderRichtext(Infinity)(item, undefined, col);
		}

		if (entityType === 'Requirement') {
			const isStatus: boolean = getIfIsStatusField(path);
			if (isStatus) return renderRequirementStatus()(item, undefined, col);

			const isCategory: boolean = getIfIsCategoryField(path);
			if (isCategory) return renderRequirementCategory()(item, undefined, col);
		}

		if (path.includes('Refs') && (type === 'ADD' || type === 'REMOVE')) {
			const refValue = values?.find((v: any) => v.id === nodeId);
			return <Text>{refValue?.name}</Text>;
		}

		if (path.includes('isElectro')) {
			return renderBoolean()(item, undefined, col);
		}

		return renderCommonAuditValues({fieldName, value, log, col});
	};

const getIfCorrectChangeTypeIsAdd = (auditLog: AuditLog): boolean => {
	const changeType: ChangeType = getChangeType(auditLog);
	return changeType === ChangeType.Add;
};

const renderAuditOldValue: (fieldName: string, values?: any) => RenderFn =
	(fieldName, values) => (log: AuditLog) => {
		const {i18n} = useTranslation();
		const {change, entityType} = log;
		const {path, oldValue, type, nodeId} = change;

		const item: any = {};
		item[fieldName] = oldValue;

		const col = createColumn(fieldName);

		const isAddition: boolean = getIfCorrectChangeTypeIsAdd(log);
		if (isAddition) return <></>;

		if (path.includes('derivativeKind')) {
			return (
				<span>
					{parseInt(oldValue, 10) === 1 ? 'Neuer Typ' : 'Neues Fahrzeug'}
				</span>
			);
		}

		if (path.includes('date')) {
			if (oldValue < 0) {
				return <span></span>;
			}

			return renderAuditValueAsDate(oldValue, i18n);
		}

		if (path.startsWith('$.summary') || path.startsWith('$.definition')) {
			return renderRichtext(Infinity)(item, undefined, col);
		}

		if (entityType === 'Requirement') {
			const isStatus: boolean = getIfIsStatusField(path);
			if (isStatus) return renderRequirementStatus()(item, undefined, col);

			const isCategory: boolean = getIfIsCategoryField(path);
			if (isCategory) return renderRequirementCategory()(item, undefined, col);
		}

		if (path.includes('Refs') && (type === 'ADD' || type === 'REMOVE')) {
			const refValue = values?.find((v: any) => v.id === nodeId);
			return <Text>{refValue?.name}</Text>;
		}

		return renderCommonAuditValues({fieldName, value: oldValue, log, col});
	};
