import {
	DetailsRow,
	IDetailsRowProps,
	IDetailsRowStyles,
	IRenderFunction,
	useTheme,
} from '@fluentui/react';
import {EntityListColumn} from 'components';
import {getParagraphListBaseColumns} from 'features/RegulatoryDocuments/components/DocumentDetails';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {RegulatoryDocumentParagraph} from 'types';
import {FullTextSearchQuery} from '../hooks';
import {SearchResultList} from './SearchResultList';
import {RegDocParagraphTooltipTranslationProvider} from 'features/localizedTooltips';
import {useRegDocsTooltipText} from 'features/RegulatoryDocuments/components/RegDocTooltipTranslationProvider';

export const ParagraphResultsList: React.FC<{
	paragraphs: FullTextSearchQuery['fullTextSearch']['paragraphs'];
}> = ({paragraphs}) => {
	const theme = useTheme();
	const {t} = useTranslation('features/regulatorydocuments', {
		keyPrefix: 'ParagraphsList',
	});

	const onRenderRow: IRenderFunction<IDetailsRowProps> = (
		rowProps: IDetailsRowProps | undefined,
	) => {
		const customStyles: Partial<IDetailsRowStyles> = {};

		if (rowProps) {
			const {isFootnote} = rowProps.item;
			customStyles.fields = {
				display: 'flex',
				alignItems: 'start',
				background: isFootnote ? theme.palette.neutralLight : undefined,
			};

			return <DetailsRow {...rowProps} styles={customStyles} />;
		}

		return null;
	};

	const {t: getRegDocsTooltipText} = useRegDocsTooltipText();

	const columns = React.useMemo(
		() =>
			[
				{
					key: 'c-regulation',
					name: 'Regulation',
					minWidth: 100,
					getFieldValue: (item: RegulatoryDocumentParagraph) =>
						`${item.parent.regulation?.regulationNumber} ${item.parent.regulation?.name}`,
					isResizable: true,
					filterable: true,
					onRender: (item: RegulatoryDocumentParagraph) =>
						`${item.parent.regulation?.regulationNumber} ${item.parent.regulation?.name}`,
					tooltipHostProps: {content: getRegDocsTooltipText('regulation')},
				},
				{
					key: 'c-parent',
					name: 'Version',
					fieldName: 'parent',
					minWidth: 100,
					isResizable: true,
					filterable: true,
					onRender: (item: RegulatoryDocumentParagraph) => item.parent.name,
				},
				...getParagraphListBaseColumns(t),
			] as EntityListColumn[],
		[t],
	);

	return (
		<RegDocParagraphTooltipTranslationProvider>
			<SearchResultList
				items={paragraphs}
				columns={columns}
				getItemUrl={par =>
					`/regulations/${par.parent.regulation?.id}/${par.parent.id}/paragraphs/${par.id}`
				}
				onRenderRow={onRenderRow}
			/>
		</RegDocParagraphTooltipTranslationProvider>
	);
};
