import React from 'react';
import {useParams} from 'react-router-dom';
import {LoadWrapper} from 'components/LoadWrapper';
import {BaselineDetailsPageParams} from './BaselineDetailsPage.types';
import {BaselineBreadcrumbs} from '../baselines/BaselineBreadcrumbs';
import {NotFoundPage} from 'components/NotFoundPage';
import {useBaselineDetailsPageTranslations} from './BaselineDetailsPage.utils';
import {useBaselineCmds} from '../baselines/baselineCmds';
import {RequirementsTooltipTranslationProvider} from 'features/Requirements/RequirementsTooltipTranslationProvider';
import {useBoolean} from '@fluentui/react-hooks';
import {BooleanState} from 'types';
import {useCommand} from 'hooks';
import {RequirementsList} from './RequirementsList';
import {BaselinesSelectionDialog} from './BaselinesSelectionDialog/BaselinesSelectionDialog';
import {
	Baseline,
	Baselines,
	BaselineDetailsPageResult,
} from './BaselineDetailsPage.types';
import {BaselineDetailsSection} from '../baselines/BaselineDetailsSection';
import _ from 'lodash';
import {useGetBaselinesWithRequirementsQuery} from '../hooks/baseline.generated';

const emptyArray: never[] = [];

export function BaselineDetailsPage() {
	const params = useParams() as unknown as BaselineDetailsPageParams;
	const {t} = useBaselineDetailsPageTranslations();
	const infoForIsComparisonDialogOpen: BooleanState = useBoolean(false);
	useBaselineCmds({params, showCreationCmd: false});

	const {data, loading}: BaselineDetailsPageResult =
		useGetBaselinesWithRequirementsQuery({
			variables: params,
			/**
			 * We must disable the cache because otherwise, Apollo will show the wrong
			 * data for requirements. See the docs for more info.
			 *
			 * If you want to change this fetch policy, read docs on queries and
			 * mutations. Also, be sure to refetch this query whenever the list of
			 * baselines changes.
			 */
			fetchPolicy: 'no-cache',
		});

	const baselines: Baselines =
		data?.vehicleProjectBaselinesByModelAndSeries ?? emptyArray;

	type PossibleBaseline = Baseline | undefined;

	const findBaseline = (): PossibleBaseline => {
		return _.find(baselines, {id: params.id});
	};

	const baseline: PossibleBaseline = findBaseline();

	const useComparisonCommand = (): void => {
		const [, {setTrue: showComparisonDialog}] = infoForIsComparisonDialogOpen;
		useCommand(
			{
				key: 'compare',
				text: t('ComparisonCmdTxt'),
				iconProps: {iconName: 'BranchCompare'},
				disabled: !baseline,
				onClick: showComparisonDialog,
			},
			[baseline, showComparisonDialog, t],
		);
	};

	useComparisonCommand();

	if (loading) return <LoadWrapper loading={loading} />;
	if (!baseline) return <NotFoundPage />;

	const {name} = baseline;

	return (
		<>
			<BaselineBreadcrumbs baselineName={name} params={params} />
			<BaselineDetailsSection baselineName={name} />
			<RequirementsTooltipTranslationProvider>
				<RequirementsList baseline={baseline} />
			</RequirementsTooltipTranslationProvider>
			<BaselinesSelectionDialog
				infoForIsOpen={infoForIsComparisonDialogOpen}
				params={params}
			/>
		</>
	);
}
