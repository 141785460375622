import {mergeStyleSets, Theme} from '@fluentui/react';
import {CompareResult} from '../compareDocuments/compareDocuments';
import {useTranslation} from 'react-i18next';

export const getClassNames = (theme: Theme) =>
	mergeStyleSets({
		statusBox: {
			display: 'flex',
			alignItems: 'center',
			marginRight: 25,
		},
		itemHeader: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
		paragraphItem: {
			color: theme.palette.neutralSecondary,
			fontSize: 14,
		},
		enumeration: {
			whiteSpace: 'no-wrap',
			fontWeight: 'bold',
		},
		elemBox: {
			whiteSpace: 'normal',
			paddingRight: 10,
		},
		iconStyles: {
			marginRight: 10,
			borderRadius: '50%',
		},
		changeMark: {
			background: theme.palette.yellow,
		},
	});

export const getComparisonColor = (result: CompareResult, theme: Theme) => {
	let dotColor;

	switch (result) {
		case CompareResult.Deleted:
			dotColor = theme.palette.redDark;
			break;
		case CompareResult.New:
			dotColor = theme.palette.green;
			break;
		case CompareResult.Changed:
			dotColor = theme.palette.yellow;
			break;
		case CompareResult.Equal:
			dotColor = theme.palette.neutralLight;
			break;
		case CompareResult.InReview:
			dotColor = theme.palette.blue;
			break;
		default:
			dotColor = theme.palette.neutralLight;
	}

	return dotColor;
};

export const useTranslationsOfCompareVersionPanel = () => {
	return useTranslation('features/regulatorydocuments', {
		keyPrefix: 'CompareVersionPanel',
	});
};
