import {
	DefaultButton,
	DetailsRow,
	Dialog,
	DialogFooter,
	DialogType,
	FontIcon,
	IDetailsRowStyles,
	PrimaryButton,
	TextField,
	useTheme,
} from '@fluentui/react';
import {EntityListColumn} from 'components';
import {getDateColumn} from 'helpers';
import React from 'react';
import {useParams} from 'react-router-dom';
import {Requirement, VehicleProject} from 'types';
import {
	GetVehicleProjectsByGenerationAndModelSeriesDocument,
	useRemoveRequirementsVehicleProjectBatchMutation,
} from '../../hooks';
import {
	createLocalizedTooltipsNamespace,
	useTooltipTranslation,
} from 'features/localizedTooltips';
import {useVehicleProjectsTranslation} from '../../hooks/vehicleProjectsTranslationHooks';
import {useBaselineCmds} from '../../baselines/baselineCmds';
import {ProjectDetailsPageParams} from 'features/VehicleProjects/vehicleProjectPagePaths';
import {
	RequirementsList,
	useReqColumnsOfDetailsPage,
} from './RequirementsList/RequirementsList';
import {useSelection} from 'hooks';
import {NonNullableDataOfProjectDetailsPage} from 'features/VehicleProjects/VehicleProjectDetailsPage.types';

type AssignedRequirements =
	NonNullableDataOfProjectDetailsPage['assignedRequirements'];

type AssignedRequirement = AssignedRequirements[number];

const useAssignedRequirementsTooltips = () => {
	const namespace: string = createLocalizedTooltipsNamespace(
		'assignedRequirements',
	);
	return useTooltipTranslation(namespace);
};

interface Props {
	vehicleProjectId: VehicleProject['id'];
	items: AssignedRequirements;
}

export const AssignedRequirementsList: React.FC<Props> = ({
	items,
	vehicleProjectId,
	...props
}) => {
	const {t} = useVehicleProjectsTranslation();

	const theme = useTheme();
	/**
	 * Note: The dialog appears to never show.
	 */
	const [showDialog, setShowDialog] = React.useState(false);
	const [selectedRequirements, setSelectedRequirements] = React.useState<
		Requirement[]
	>([]);

	const params = useParams() as ProjectDetailsPageParams;

	useBaselineCmds({params, showCreationCmd: false});

	const [reason, setReason] = React.useState('');

	const [removeRequirementsBatch] =
		useRemoveRequirementsVehicleProjectBatchMutation();

	const [selection] = useSelection<Requirement>({
		onSelectionChanged(selectedItems) {
			setSelectedRequirements(selectedItems);
		},
		getKey: item => item.id,
	});

	type ItemFieldsFromAssignedRequirement = Pick<
		AssignedRequirement,
		| 'deleted'
		| 'addedManually'
		| 'removeReason'
		| 'addReason'
		| 'added'
		| 'removed'
	>;

	type Item = ItemFieldsFromAssignedRequirement &
		AssignedRequirement['requirement'];

	const requirements: Item[] = React.useMemo(() => {
		const createItemsFromAssignedReqs = (): Item[] => {
			return items
				.map(a => ({
					deleted: a.deleted,
					addedManually: a.addedManually,
					removeReason: a.removeReason,
					addReason: a.addReason,
					added: a.added,
					removed: a.removed,
					...a.requirement,
				}))
				.sort((a, b) => (a.deleted as any) - (b.deleted as any));
		};

		return createItemsFromAssignedReqs();
	}, [items]);

	const handleDeleteClick = React.useCallback(async (): Promise<void> => {
		await removeRequirementsBatch({
			variables: {
				input: {
					requirementIds: selectedRequirements.map(sr => sr.id),
					vehicleProjectIds: [vehicleProjectId],
					removeReason: reason,
				},
			},
			refetchQueries: [GetVehicleProjectsByGenerationAndModelSeriesDocument],
		});

		setShowDialog(false);
	}, [selectedRequirements, reason, removeRequirementsBatch, vehicleProjectId]);

	const {t: translateForAssignedRequirements} =
		useAssignedRequirementsTooltips();

	const {t: translateForRequirementsList} = useTooltipTranslation(
		'features/VehicleProjects/requirementsList',
	);

	const reqColumns: EntityListColumn[] = useReqColumnsOfDetailsPage();

	const columns = React.useMemo((): EntityListColumn[] => {
		interface Column extends EntityListColumn {
			/**
			 * We use a boolean to detect if it's a column for an assigned req instead
			 * of checking the field name because this is cleaner.
			 */
			addTooltipFromAssignedReqFieldName?: boolean;
		}

		interface ColumnWithFieldName extends Omit<Column, 'fieldName'> {
			fieldName: string;
		}

		const getAssignedReqColumnWithTooltip = (
			column: ColumnWithFieldName,
		): ColumnWithFieldName => {
			const content: string = translateForAssignedRequirements(
				column.fieldName,
			);
			return {...column, tooltipHostProps: {content}};
		};

		const getAssignedReqColumnWithTooltipIfPossible = (
			baseColumn: Column,
		): Column => {
			if (!baseColumn.fieldName) return baseColumn;
			return getAssignedReqColumnWithTooltip(baseColumn as ColumnWithFieldName);
		};

		const getColumn = (baseColumn: Column): Column => {
			if (baseColumn.addTooltipFromAssignedReqFieldName)
				return getAssignedReqColumnWithTooltipIfPossible(baseColumn);
			return baseColumn;
		};

		const renderDeleted = (item: Item) => {
			let icon;

			if (item.deleted) {
				icon = (
					<div>
						<FontIcon
							iconName='RemoveFromShoppingList'
							style={{color: 'red'}}
						/>
					</div>
				);
			} else if (item.addedManually === false) {
				icon = <div></div>;
			} else {
				icon = (
					<div>
						<FontIcon iconName='AddToShoppingList' style={{color: 'green'}} />
					</div>
				);
			}

			return icon;
		};

		type ReasonValue = Item['removeReason'] | Item['addReason'];

		const getFieldsForFinalColumns = (): Column[] => {
			return [
				{
					...getDateColumn('added', t('Added'), 'added', true),
					addTooltipFromAssignedReqFieldName: true,
				},
				{
					...getDateColumn('removed', t('Removed'), 'removed', true),
					addTooltipFromAssignedReqFieldName: true,
				},
				{
					key: 'removeReason',
					name: t('RemoveReason'),
					minWidth: 150,
					maxWidth: 200,
					isResizable: true,
					isMultiline: true,
					tooltipHostProps: {
						content: translateForRequirementsList('reason'),
					},
					onRender({deleted, removeReason, addReason}: Item): ReasonValue {
						return deleted ? removeReason : addReason;
					},
				},
			];
		};

		const addCreationFieldsForDeletedField = (
			columns: EntityListColumn[],
		): void => {
			const creationFields: Column = {
				name: t('Deleted'),
				key: 'deleted',
				fieldName: 'deleted',
				minWidth: 100,
				maxWidth: 200,
				isResizable: true,
				isSorted: true,
				onRender: item => renderDeleted(item),
				addTooltipFromAssignedReqFieldName: true,
			};
			columns.splice(11, 0, creationFields);
		};

		const getColumnCreationFields = (): Column[] => {
			/**
			 * We create a copy to avoid mutating it.
			 */
			const baseFieldsList: Column[] = [...reqColumns];
			addCreationFieldsForDeletedField(baseFieldsList);
			const finalCreationFields: Column[] = getFieldsForFinalColumns();
			return [...baseFieldsList, ...finalCreationFields];
		};

		const fieldsList: EntityListColumn[] = getColumnCreationFields();
		return fieldsList.map(getColumn);
	}, [
		t,
		reqColumns,
		translateForAssignedRequirements,
		translateForRequirementsList,
	]);

	const renderRow = React.useCallback(
		(row: any) => {
			const customRowStyle: Partial<IDetailsRowStyles> = {};
			if (row?.item?.deleted) {
				customRowStyle.root = {
					backgroundColor: theme.palette.themeLighterAlt,
					color: theme.palette.neutralSecondaryAlt,
				};
			}

			return <DetailsRow {...row} styles={customRowStyle} />;
		},
		[theme],
	);

	const onDismiss = React.useCallback(() => setShowDialog(false), []);

	const onInputChange = React.useCallback(
		(_: any, val?: string) => setReason(val ?? ''),
		[],
	);

	return (
		<div>
			<RequirementsList
				{...props}
				columns={columns}
				items={requirements}
				onRenderRow={renderRow}
				selection={selection}
			/>
			<Dialog
				hidden={!showDialog}
				dialogContentProps={{
					type: DialogType.normal,
					title: t('ConfirmDelete'),
					subText: t('ConfirmDeleteSubtext'),
				}}
				modalProps={{isBlocking: true, styles: {main: {maxWidth: 450}}}}
				onDismiss={onDismiss}
			>
				<TextField
					multiline
					label={t('ReasonLabel')}
					onChange={onInputChange}
					autoAdjustHeight
				/>
				<DialogFooter>
					<PrimaryButton onClick={handleDeleteClick} text={t('RemoveButton')} />
					<DefaultButton onClick={onDismiss} text={t('CancelButton')} />
				</DialogFooter>
			</Dialog>
		</div>
	);
};
