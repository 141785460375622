import {Requirement, RegulatoryDocumentParagraph} from 'types';
import {
	IHTMLXL,
	renderHeaderXL,
	renderHTMLExcel,
	cleanHTMLExcel,
	createZIPXL,
} from './ExcelExportCommon';
import {TFunction} from 'react-i18next';
import {formatDateTime} from 'i18n/localeDateFormat';
import i18n from 'i18n/i18n';
import Excel, {RichText} from 'exceljs';
import JSZip from 'jszip';

interface IRequirementDetailPage {
	(entityListItems: any[], pageDetails: any, t: TFunction): Promise<void>;
}

export const handleClickRequirementDetailPage: IRequirementDetailPage = async (
	entityListItems,
	pageDetails,
	t,
) => {
	const requirement = pageDetails as Requirement;
	const arrParaFilt = entityListItems as RegulatoryDocumentParagraph[];

	const arrImagesUri: string[] = [];
	const arrImagesName: string[] = [];
	const arrTables: string[] = [];

	const workbook = new Excel.Workbook();
	const arrColWidth = [
		30, 30, 100, 40, 20, 100, 20, 20, 20, 20, 20, 20, 20, 20, 20,
	];
	const strPageName = t('AssignedParagraphs', {ns: 'features/requirements'});
	const sheet = workbook.addWorksheet('TRACE_' + strPageName);

	renderHeaderXL(workbook, sheet, arrColWidth, strPageName, t);

	let intRowCount = 4;
	let row = sheet.getRow(intRowCount);
	row.alignment = {wrapText: true, vertical: 'top'};

	row.getCell('B').value = {
		richText: [
			{
				font: {bold: true, name: 'Porsche Next TT', size: 10},
				text: t('RequirementId', {ns: 'features/requirements'}) + '\n',
			},
			{
				font: {bold: false, name: 'Porsche Next TT', size: 10},
				text: requirement.requirementId ?? '',
			},
		],
	};

	row.getCell('C').value = {
		richText: [
			{
				font: {bold: true, name: 'Porsche Next TT', size: 10},
				text: t('Name', {ns: 'features/requirements'}) + '\n',
			},
			{
				font: {bold: false, name: 'Porsche Next TT', size: 10},
				text: requirement.name ?? '',
			},
		],
	};

	const tempDivElement = document.createElement('div');
	let arrHTMLXL: IHTMLXL[];
	let arrHTMLXLClean: IHTMLXL[];

	arrHTMLXL = [];
	arrHTMLXLClean = [
		{
			font: {bold: true, name: 'Porsche Next TT', size: 10},
			text: t('Definition', {ns: 'features/requirements'}) + '\n',
		},
	];

	tempDivElement.innerHTML = requirement.definition
		? '<div>' + requirement.definition + '</div>'
		: '<div></div>';
	renderHTMLExcel(tempDivElement.childNodes[0].childNodes, arrHTMLXL);
	cleanHTMLExcel(arrHTMLXL, arrHTMLXLClean);

	row.getCell('D').value = {
		richText: arrHTMLXLClean as RichText[],
	};
	intRowCount++;

	for (
		let jj = 0;
		jj < tempDivElement.getElementsByTagName('img').length;
		jj++
	) {
		arrImagesUri.push(
			tempDivElement
				.getElementsByTagName('img')[0]
				.getAttribute('src') as string,
		);
		arrImagesName.push(
			(requirement.requirementId as string) + '_' + jj + '.png',
		);
		row = sheet.getRow(intRowCount);
		row.outlineLevel = 1;
		row.getCell('B').fill = {
			type: 'pattern',
			pattern: 'solid',
			fgColor: {argb: 'BFBFBF'},
		};

		row.getCell('D').alignment = {
			indent: 1,
			wrapText: true,
			vertical: 'top',
		};
		row.getCell('D').style.font = {
			underline: true,
			bold: false,
			color: {argb: '00B0F0'},
		};
		row.getCell('D').value = {
			text: t('LinkImage', {ns: 'components/EntityList/ExcelExport'}),
			hyperlink:
				(('assets/' + requirement.requirementId) as string) + '_' + jj + '.png',
		};

		intRowCount++;
	}

	row = sheet.getRow(intRowCount);
	row.alignment = {wrapText: true, vertical: 'top'};

	arrHTMLXL = [];
	arrHTMLXLClean = [];
	row.getCell('B').value = {
		richText: [
			{
				font: {bold: true, name: 'Porsche Next TT', size: 10},
				text: t('Status', {ns: 'features/requirements'}) + '\n',
			},
			{
				font: {bold: false, name: 'Porsche Next TT', size: 10},
				text: requirement.status ? requirement.status : '',
			},
		],
	};

	row.getCell('C').value = {
		richText: [
			{
				font: {bold: true, name: 'Porsche Next TT', size: 10},
				text: t('VexCluster', {ns: 'features/requirements'}) + '\n',
			},
			{
				font: {bold: false, name: 'Porsche Next TT', size: 10},
				text: requirement.vexClusters.map(vex => vex.name).join(';\n'),
			},
		],
	};

	row.getCell('D').value = {
		richText: [
			{
				font: {bold: true, name: 'Porsche Next TT', size: 10},
				text: t('SystemLevels', {ns: 'features/requirements'}) + '\n',
			},
			{
				font: {bold: false, name: 'Porsche Next TT', size: 10},
				text: requirement.systemLevels.map(level => level.name).join('\n'),
			},
		],
	};

	intRowCount++;
	row = sheet.getRow(intRowCount);
	row.alignment = {wrapText: true, vertical: 'top'};

	row.getCell('B').value = {
		richText: [
			{
				font: {bold: true, name: 'Porsche Next TT', size: 10},
				text: t('Markets', {ns: 'features/requirements'}) + '\n',
			},
			{
				font: {bold: false, name: 'Porsche Next TT', size: 10},
				text: requirement.markets.map(market => market.name).join('\n'),
			},
		],
	};

	row.getCell('C').value = {
		richText: [
			{
				font: {bold: true, name: 'Porsche Next TT', size: 10},
				text: t('Keywords', {ns: 'features/requirements'}) + '\n',
			},
			{
				font: {bold: false, name: 'Porsche Next TT', size: 10},
				text: requirement.keywords.map(keyword => keyword.name).join('\n'),
			},
		],
	};

	row.getCell('E').value = {
		richText: [
			{
				font: {bold: true, name: 'Porsche Next TT', size: 10},
				text: t('Tags', {ns: 'features/requirements'}) + '\n',
			},
			{
				font: {bold: false, name: 'Porsche Next TT', size: 10},
				text: requirement.tags.map(tag => tag.name).join('\n'),
			},
		],
	};

	intRowCount++;
	row = sheet.getRow(intRowCount);
	row.alignment = {wrapText: true, vertical: 'top'};

	row.getCell('B').value = {
		richText: [
			{
				font: {bold: true, name: 'Porsche Next TT', size: 10},
				text: t('DriveVariants', {ns: 'features/requirements'}) + '\n',
			},
			{
				font: {bold: false, name: 'Porsche Next TT', size: 10},
				text: requirement.driveVariants.map(drive => drive.name).join(';\n'),
			},
		],
	};

	row.getCell('C').value = {
		richText: [
			{
				font: {bold: true, name: 'Porsche Next TT', size: 10},
				text: t('EngineVariants', {ns: 'features/requirements'}) + '\n',
			},
			{
				font: {bold: false, name: 'Porsche Next TT', size: 10},
				text: requirement.engineVariants.map(engine => engine.name).join('\n'),
			},
		],
	};

	row.getCell('D').value = {
		richText: [
			{
				font: {bold: true, name: 'Porsche Next TT', size: 10},
				text: t('GearboxVariants', {ns: 'features/requirements'}) + '\n',
			},
			{
				font: {bold: false, name: 'Porsche Next TT', size: 10},
				text: requirement.gearboxVariants.map(gear => gear.name).join('\n'),
			},
		],
	};

	intRowCount++;
	row = sheet.getRow(intRowCount);
	row.alignment = {wrapText: true, vertical: 'top'};

	row.getCell('B').value = {
		richText: [
			{
				font: {bold: true, name: 'Porsche Next TT', size: 10},
				text: t('BodyworkVariants', {ns: 'features/requirements'}) + '\n',
			},
			{
				font: {bold: false, name: 'Porsche Next TT', size: 10},
				text: requirement.bodyworkVariants.map(body => body.name).join(';\n'),
			},
		],
	};

	row.getCell('C').value = {
		richText: [
			{
				font: {bold: true, name: 'Porsche Next TT', size: 10},
				text: t('ActiveTypeMarkets', {ns: 'features/requirements'}) + '\n',
			},
			{
				font: {bold: false, name: 'Porsche Next TT', size: 10},
				text: requirement.activeTypeMarkets
					.map(market => market.name)
					.join('\n'),
			},
		],
	};

	row.getCell('D').value = {
		richText: [
			{
				font: {bold: true, name: 'Porsche Next TT', size: 10},
				text: t('VehicleCategory', {ns: 'features/requirements'}) + '\n',
			},
			{
				font: {bold: false, name: 'Porsche Next TT', size: 10},
				text: requirement.vehicleCategories.map(veh => veh.name).join('\n'),
			},
		],
	};

	if (requirement.attachments && requirement.attachments.length > 0) {
		intRowCount++;
		row = sheet.getRow(intRowCount);
		row.getCell('B').value = {
			richText: [
				{
					font: {bold: true, name: 'Porsche Next TT', size: 10},
					text: t('Attachments', {ns: 'features/requirements'}) + '\n',
				},
				{
					font: {bold: false, name: 'Porsche Next TT', size: 10},
					text: requirement.attachments
						?.map(attach => attach.attachmentId)
						.join('; '),
				},
			],
		};
		row.getCell('B').alignment = {wrapText: true, vertical: 'top'};

		intRowCount++;
		row = sheet.getRow(intRowCount);
		row.getCell('B').value = {
			text: t('LinkAttach', {ns: 'components/EntityList/ExcelExport'}),
			hyperlink: 'attachments',
		};
		row.getCell('B').style.font = {
			underline: true,
			bold: false,
			color: {argb: '00B0F0'},
		};
	}

	intRowCount++;
	intRowCount++;
	row = sheet.getRow(intRowCount);
	row.font = {
		bold: true,
		name: 'Porsche Next TT',
		size: 10,
		color: {argb: 'FFFFFFFF'},
	};

	sheet.addTable({
		name: 'tableSegments',
		ref: 'B' + String(intRowCount),
		style: {
			showRowStripes: true,
		},
		columns: [
			{
				name: t('Regulation', {
					ns: 'features/regulatorydocuments',
					keyPrefix: 'ParagraphsList',
				}),
				filterButton: true,
			},
			{
				name: t('Version', {
					ns: 'features/regulatorydocuments',
					keyPrefix: 'ParagraphsList',
				}),
				filterButton: true,
			},
			{
				name: t('Paragraph', {
					ns: 'features/regulatorydocuments',
					keyPrefix: 'ParagraphsList',
				}),
				filterButton: false,
			},
			{
				name: t('Categories', {
					ns: 'features/regulatorydocuments',
					keyPrefix: 'ParagraphsList',
				}),
				filterButton: false,
			},
			{
				name: t('Keywords', {
					ns: 'features/regulatorydocuments',
					keyPrefix: 'ParagraphsList',
				}),
				filterButton: false,
			},
			{
				name: t('Requirements', {
					ns: 'features/regulatorydocuments',
					keyPrefix: 'ParagraphsList',
				}),
				filterButton: false,
			},
			{
				name: t('Tags', {
					ns: 'features/regulatorydocuments',
					keyPrefix: 'ParagraphsList',
				}),
				filterButton: false,
			},
			{
				name: t('VehicleCategory', {
					ns: 'features/regulatorydocuments',
					keyPrefix: 'ParagraphsList',
				}),
				filterButton: false,
			},
			{
				name: t('DriveVariants', {
					ns: 'features/regulatorydocuments',
					keyPrefix: 'ParagraphsList',
				}),
				filterButton: false,
			},
			{
				name: t('DateNewTypes', {
					ns: 'features/regulatorydocuments',
					keyPrefix: 'ParagraphsList',
				}),
				filterButton: true,
			},
			{
				name: t('DateNewRegistration', {
					ns: 'features/regulatorydocuments',
					keyPrefix: 'ParagraphsList',
				}),
				filterButton: true,
			},
			{
				name: t('Comprehensive', {
					ns: 'features/regulatorydocuments',
					keyPrefix: 'ParagraphsList',
				}),
				filterButton: true,
			},
			{
				name: t('ModelYear', {
					ns: 'features/regulatorydocuments',
					keyPrefix: 'ParagraphsList',
				}),
				filterButton: true,
			},
			{
				name: t('PhaseIn', {
					ns: 'features/regulatorydocuments',
					keyPrefix: 'ParagraphsList',
				}),
				filterButton: false,
			},
			{
				name: t('PhaseOut', {
					ns: 'features/regulatorydocuments',
					keyPrefix: 'ParagraphsList',
				}),
				filterButton: false,
			},
		],
		rows: [],
	});

	const table = sheet.getTable('tableSegments');

	let arrRichTexts = [];
	let arrRichTextsReq = [];

	let intTableCount = 0;

	let regulationNumber = '';
	let regulationVerion = '';
	let category = '';
	let keyword = '';
	let tag = '';
	let vehClass = '';
	let drivetrain = '';
	let dateNT = '';
	let dateNF = '';
	let dateUB = '';
	let modelyear = '';
	let phaseIn = '';
	let phaseOut = '';

	if (arrParaFilt.length === 0) {
		table.addRow(['', '', '', '', '', '', '', '', '', '', '', '', '', '', '']);
	}

	intRowCount++;
	for (let ii = 0; ii < arrParaFilt.length; ii++) {
		const arrRequirementsSorted = arrParaFilt[ii].requirements.slice();
		arrRequirementsSorted.sort(function (a, b) {
			return a.requirementId! < b.requirementId! ? -1 : 1;
		});
		arrRichTexts = [];
		arrRichTextsReq = [];
		regulationNumber = arrParaFilt[ii].parent.regulation!.regulationNumber;
		regulationVerion = arrParaFilt[ii].parent.name;

		category = arrParaFilt[ii].categories
			.map(category => category.name)
			.join(';\n');

		keyword = arrParaFilt[ii].keywords.map(keyword => keyword.name).join(';\n');

		tag = arrParaFilt[ii].tags.map(tag => tag.name).join(';\n');

		vehClass = arrParaFilt[ii].vehicleCategories
			.map(category => category.name)
			.join(';\n');

		drivetrain = arrParaFilt[ii].driveVariants
			.map(drive => drive.name)
			.join(';\n');

		dateNT = arrParaFilt[ii].dateNewTypes
			? formatDateTime(new Date(arrParaFilt[ii].dateNewTypes), i18n)
			: '';

		dateNF = arrParaFilt[ii].dateNewRegistration
			? formatDateTime(new Date(arrParaFilt[ii].dateNewRegistration), i18n)
			: '';

		dateUB = arrParaFilt[ii].comprehensive
			? formatDateTime(new Date(arrParaFilt[ii].comprehensive), i18n)
			: '';

		modelyear = String(arrParaFilt[ii].modelYear ?? '');

		phaseIn = (arrParaFilt[ii].phaseIn ?? [])
			.map(
				PI =>
					(PI.date ? formatDateTime(new Date(PI.date), i18n) : '') +
					' (' +
					(PI.status ?? '') +
					')',
			)
			.join(';\n');

		phaseOut = (arrParaFilt[ii].phaseOut ?? [])
			.map(
				PI =>
					(PI.date ? formatDateTime(new Date(PI.date), i18n) : '') +
					' (' +
					(PI.status ?? '') +
					')',
			)
			.join(';\n');

		for (let jj = 0; jj < arrRequirementsSorted.length; jj++) {
			arrRichTextsReq.push({
				font: {bold: true, name: 'Porsche Next TT', size: 10},
				text:
					arrRequirementsSorted[jj].name +
					' (' +
					arrRequirementsSorted[jj].requirementId +
					')\n\n',
			});
		}

		for (let jj = 0; jj < arrParaFilt[ii].elements.length; jj++) {
			if (String(arrParaFilt[ii].elements[jj].type) === 'TEXT') {
				if (arrParaFilt[ii].elements[jj].isHeader === true) {
					arrRichTexts.push({
						font: {bold: true, name: 'Porsche Next TT', size: 10},
						text: arrParaFilt[ii].elements[jj].text + '\n',
					});
				} else {
					arrRichTexts.push({
						font: {bold: false, name: 'Porsche Next TT', size: 10},
						text: arrParaFilt[ii].elements[jj].text + '\n',
					});
				}
			}

			if (String(arrParaFilt[ii].elements[jj].type) === 'NEW_LINE') {
				arrRichTexts.push({text: '\n'});
			}

			if (jj === arrParaFilt[ii].elements.length - 1) {
				row = sheet.getRow(intRowCount);
				row.alignment = {wrapText: true, vertical: 'top'};
				row.alignment = {wrapText: true, vertical: 'top'};
				table.addRow([
					regulationNumber,
					regulationVerion,
					{richText: arrRichTexts, wrapText: true, vertical: 'top'},
					category,
					keyword,
					{richText: arrRichTextsReq, wrapText: true, vertical: 'top'},
					tag,
					vehClass,
					drivetrain,
					dateNT,
					dateNF,
					dateUB,
					modelyear,
					phaseIn,
					phaseOut,
				]);
				intRowCount++;
				arrRichTexts = [];
			}
		}

		const arrLinks: string[] = [];
		const arrLinkText: string[] = [];

		for (let jj = 0; jj < arrParaFilt[ii].elements.length; jj++) {
			if (
				String(arrParaFilt[ii].elements[jj].type) === 'IMAGE' ||
				String(arrParaFilt[ii].elements[jj].type) === 'TABLE'
			) {
				arrImagesUri.push(String(arrParaFilt[ii].elements[jj].asset?.uri));
				arrImagesName.push(String(arrParaFilt[ii].elements[jj].text));
				arrLinks.push('assets/' + arrImagesName[arrImagesName.length - 1]);
				arrLinkText.push(
					t('LinkImage', {ns: 'components/EntityList/ExcelExport'}),
				);
			}

			if (String(arrParaFilt[ii].elements[jj].type) === 'HTML_TABLE') {
				arrTables.push(String(arrParaFilt[ii].elements[jj].text));
				arrLinks.push('assets/html_table_' + intTableCount + '.html');
				arrLinkText.push(
					t('LinkTable', {ns: 'components/EntityList/ExcelExport'}),
				);
				intTableCount++;
			}
		}

		let intRowCountReq = 0;
		let arrHTMLXL: IHTMLXL[];
		let arrHTMLXLClean: IHTMLXL[];
		for (let jj = 0; jj < arrRequirementsSorted.length; jj++) {
			arrHTMLXL = [];
			arrHTMLXLClean = [
				{
					font: {bold: true, name: 'Porsche Next TT', size: 10},
					text:
						arrRequirementsSorted[jj].name +
						' (' +
						arrRequirementsSorted[jj].requirementId +
						')\n',
				},
			];
			tempDivElement.innerHTML = arrRequirementsSorted[jj].definition
				? '<div>' + arrRequirementsSorted[jj].definition + '</div>'
				: '<div></div>';
			renderHTMLExcel(tempDivElement.childNodes[0].childNodes, arrHTMLXL);
			cleanHTMLExcel(arrHTMLXL, arrHTMLXLClean);

			for (
				let jj = 0;
				jj < tempDivElement.getElementsByTagName('img').length;
				jj++
			) {
				if (
					arrImagesName.indexOf(
						(arrRequirementsSorted[jj].requirementId as string) +
							'_' +
							jj +
							'.png',
					) === -1
				) {
					arrImagesUri.push(
						tempDivElement
							.getElementsByTagName('img')[0]
							.getAttribute('src') as string,
					);
					arrImagesName.push(
						(arrRequirementsSorted[jj].requirementId as string) +
							'_' +
							jj +
							'.png',
					);
				}
			}

			row = sheet.getRow(intRowCount);
			row.outlineLevel = 1;
			row.alignment = {wrapText: true, vertical: 'top'};
			row.getCell('B').fill = {
				type: 'pattern',
				pattern: 'solid',
				fgColor: {argb: '8DB4E2'},
			};
			row.getCell('D').style.font = {
				underline: true,
				bold: false,
				color: {argb: '00B0F0'},
			};
			row.getCell('G').alignment = {
				indent: 1,
				wrapText: true,
				vertical: 'top',
			};
			table.addRow([
				'',
				'',
				intRowCountReq < arrLinks.length
					? {
							text: arrLinkText[intRowCountReq],
							hyperlink: arrLinks[intRowCountReq],
					  }
					: '',
				'',
				'',
				{richText: arrHTMLXLClean, wrapText: true, vertical: 'top'},
				'',
				'',
				'',
				'',
				'',
				'',
				'',
				'',
				'',
			]);
			intRowCount++;
			intRowCountReq++;

			for (
				let kk = 0;
				kk < tempDivElement.getElementsByTagName('img').length;
				kk++
			) {
				row = sheet.getRow(intRowCount);
				row.outlineLevel = 1;
				row.alignment = {wrapText: true, vertical: 'top'};
				row.getCell('B').fill = {
					type: 'pattern',
					pattern: 'solid',
					fgColor: {argb: '8DB4E2'},
				};
				row.getCell('D').style.font = {
					underline: true,
					bold: false,
					color: {argb: '00B0F0'},
				};
				row.getCell('G').alignment = {
					indent: 1,
					wrapText: true,
					vertical: 'top',
				};
				row.getCell('G').style.font = {
					underline: true,
					bold: false,
					color: {argb: '00B0F0'},
				};
				table.addRow([
					'',
					'',
					intRowCountReq < arrLinks.length
						? {
								text: arrLinkText[intRowCountReq],
								hyperlink: arrLinks[intRowCountReq],
						  }
						: '',
					'',
					'',
					{
						text: t('LinkImage', {ns: 'components/EntityList/ExcelExport'}),
						hyperlink:
							(('assets/' +
								arrRequirementsSorted[jj].requirementId) as string) +
							'_' +
							kk +
							'.png',
					},
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
				]);
				intRowCount++;
				intRowCountReq++;
			}
		}

		for (let jj = intRowCountReq; jj < arrLinks.length; jj++) {
			row = sheet.getRow(intRowCount);
			row.outlineLevel = 1;
			row.alignment = {wrapText: true, vertical: 'top'};
			row.getCell('B').fill = {
				type: 'pattern',
				pattern: 'solid',
				fgColor: {argb: '8DB4E2'},
			};
			row.getCell('D').style.font = {
				underline: true,
				bold: false,
				color: {argb: '00B0F0'},
			};
			table.addRow([
				'',
				'',
				{text: arrLinkText[jj], hyperlink: arrLinks[jj]},
				'',
				'',
				'',
				'',
				'',
				'',
				'',
				'',
				'',
				'',
				'',
				'',
			]);
			intRowCount++;
		}
	}

	table.commit();

	const strZipName =
		strPageName + '_' + atob(requirement.id).split('\n')[1].slice(1);
	await createZIPXL(
		workbook,
		strZipName,
		t,
		arrTables,
		arrImagesUri,
		arrImagesName,
		requirement.attachments!,
	);
};
