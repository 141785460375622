import {EntityListColumn, EntityPage} from 'components';
import {
	renderArrayField,
	renderPersonas,
} from 'components/EntityList/ColumnRenderers';
import {LoadWrapper} from 'components/LoadWrapper';
import {ViewAuthorizer} from 'components/ViewAuthorizer';
import {KeywordsTooltipTranslationProvider} from 'features/localizedTooltips';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {UserRole, VexCluster} from 'types';
import {useGetAllKeywordsQuery} from '../AdminSection/hooks/keywords.generated';
import {useGetAllVexClustersQuery} from '../AdminSection/hooks/vexClusters.generated';
import {DirectionalHint, PersonaSize, TooltipHost} from '@fluentui/react';
import {HeaderForPagesWithEntityPage} from 'components/HeaderForPagesWithEntityPage';

const AdminKeywordsPage: React.FC = () => {
	const {t: tKeywords} = useTranslation('features/adminsection', {
		keyPrefix: 'AdminKeywordsPage',
	});
	const {t: tClusters} = useTranslation('features/adminsection', {
		keyPrefix: 'AdminVexClustersPage',
	});

	const {loading: keywordsLoading, data: keywordData} =
		useGetAllKeywordsQuery();
	const {loading: vexClutersLoading, data: vexClustersData} =
		useGetAllVexClustersQuery();

	const keywords = React.useMemo(() => {
		if (keywordData?.keywords) {
			const sorted = [...keywordData.keywords].sort((k1, k2) =>
				(k1.name || '').localeCompare(k2.name || ''),
			);
			return sorted;
		}

		return [];
	}, [keywordData]);

	const renderClusterWithTooltip = (cluster: VexCluster) => {
		return (
			<TooltipHost
				directionalHint={DirectionalHint.leftCenter}
				tooltipProps={{
					onRenderContent() {
						const header = (
							<span>
								<span style={{fontWeight: 600}}>
									{tClusters('VexClusters')}
								</span>
								: <span>{cluster.name}</span>
							</span>
						);
						const currentCluster = vexClustersData?.vexClusters?.find(
							c => c.id === cluster.id,
						);
						const users = currentCluster?.users?.map(user => (
							<div key={user.id}>{user.name}</div>
						));

						return (
							<div>
								<div>{header}</div>
								{users && users?.length > 0 && (
									<div>
										<div style={{fontWeight: 600}}>{tClusters('Persons')}:</div>
										{users}
									</div>
								)}
							</div>
						);
					},
				}}
			>
				<>{cluster.name}</>
			</TooltipHost>
		);
	};

	const columns: EntityListColumn[] = React.useMemo(
		() => [
			{
				key: 'regulationClusters',
				name: tKeywords('RegulationClusters'),
				fieldName: 'regulationClusters',
				minWidth: 150,
				maxWidth: 200,
				isResizable: true,
				filterable: true,
				sortable: true,
				onRender: renderArrayField(),
			},
			{
				key: 'vkos',
				name: tKeywords('Vkos'),
				fieldName: 'vkos',
				minWidth: 200,
				maxWidth: 200,
				filterable: true,
				sortable: true,
				onRender: renderPersonas({size: PersonaSize.size8}),
			},
			{
				key: 'vexClusters',
				name: tKeywords('VexClusters'),
				fieldName: 'vexClusters',
				minWidth: 150,
				maxWidth: 200,
				isResizable: true,
				filterable: true,
				sortable: true,
				onRender: renderArrayField(renderClusterWithTooltip),
			},
		],
		[tKeywords, keywordData, vexClustersData],
	);

	return (
		<ViewAuthorizer
			roles={[
				UserRole.SystemAdministrator,
				UserRole.Vko,
				UserRole.Vex,
				UserRole.Readers,
			]}
		>
			<HeaderForPagesWithEntityPage>
				<h3>{tClusters('VkoVexList')}</h3>
			</HeaderForPagesWithEntityPage>
			<LoadWrapper loading={keywordsLoading || vexClutersLoading}>
				<KeywordsTooltipTranslationProvider>
					<EntityPage
						items={keywords as any}
						entityDisplayName={tKeywords('Keywords')}
						createEntity={() => null}
						updateEntity={() => null}
						deleteEntity={() => null}
						hideCreate={true}
						hideEdit={true}
						hideDelete={true}
						additionalColumns={columns}
						shouldWrapListWithScrollablePane={false}
					/>
				</KeywordsTooltipTranslationProvider>
			</LoadWrapper>
		</ViewAuthorizer>
	);
};

export default AdminKeywordsPage;
