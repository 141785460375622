import {
	NavigateToBaselinesPage,
	useNavigateToBaselinesPage,
} from 'features/VehicleProjects/BaselinesPage/BaselinesPage.utils';
import {useCommand} from 'hooks';
import {useVehicleProjectsTranslation} from '../hooks/vehicleProjectsTranslationHooks';
import {IContextualMenuItem as MenuItem} from '@fluentui/react';
import {useUserContext, UserContextType} from 'authentication/UserContext';
import {useMemo} from 'react';
import {useBoolean} from '@fluentui/react-hooks';
import {BooleanState} from 'types/fluentUi';
import {MutationResult} from '@apollo/client';

import {ProjectDetailsPageParams} from 'features/VehicleProjects/vehicleProjectPagePaths';
import {
	CreateBaseLineByModelAndSeriesMutation,
	GetBaselinesByModelAndSeriesDocument,
	useCreateBaseLineByModelAndSeriesMutation,
} from 'features/VehicleProjects/hooks/baseline.generated';

export interface BaselineCmdsInfo {
	infoForIsSuccessDialogOpen: BooleanState;
	creationResponse: MutationResult<CreateBaseLineByModelAndSeriesMutation>;
}

interface Options {
	params: ProjectDetailsPageParams;
	showOverviewCmd?: boolean;
	showCreationCmd?: boolean;
}

export const useBaselineCmds = ({
	params,
	showOverviewCmd = true,
	showCreationCmd = true,
}: Options): BaselineCmdsInfo => {
	const {isAdmin}: UserContextType = useUserContext();
	const {t} = useVehicleProjectsTranslation({keyPrefix: 'BaselineCmds'});
	const navigateToBaselinesPage: NavigateToBaselinesPage =
		useNavigateToBaselinesPage(params);

	const infoForIsSuccessDialogOpen: BooleanState = useBoolean(false);
	const [, {setTrue: setVisible}] = infoForIsSuccessDialogOpen;
	const [createBaselineByModelAndSeries, response] =
		useCreateBaseLineByModelAndSeriesMutation();

	const subcommands = useMemo((): MenuItem[] => {
		const createBaselineAndShowSuccess = async (): Promise<void> => {
			await createBaselineByModelAndSeries({
				variables: {
					generation: params.generation,
					modelSeries: params.modelSeries,
				},
				refetchQueries: [GetBaselinesByModelAndSeriesDocument],
			});

			setVisible();
		};

		/**
		 * We define another function here so that the return type is void. This is
		 * required to avoid type errors.
		 */
		const handleCreation = (): void => {
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			createBaselineAndShowSuccess();
		};

		const createCreationMenuItem = (): MenuItem => {
			return {key: 'Create', text: t('Create'), onClick: handleCreation};
		};

		const createOverviewMenuItem = (): MenuItem => {
			return {
				key: 'Overview',
				text: t('ViewOverview'),
				onClick: navigateToBaselinesPage,
			};
		};

		const getCmdsForAllRoles = (): MenuItem[] => {
			const overviewMenuItem: MenuItem = createOverviewMenuItem();
			if (showOverviewCmd) return [overviewMenuItem];
			return [];
		};

		const getCmdsForAdmin = (): MenuItem[] => {
			const creationItem: MenuItem = createCreationMenuItem();
			const cmdsForAllRoles: MenuItem[] = getCmdsForAllRoles();
			if (showCreationCmd) return [...cmdsForAllRoles, creationItem];
			return cmdsForAllRoles;
		};

		if (isAdmin) return getCmdsForAdmin();
		return getCmdsForAllRoles();
	}, [
		createBaselineByModelAndSeries,
		isAdmin,
		setVisible,
		t,
		showOverviewCmd,
		showCreationCmd,
		navigateToBaselinesPage,
		params,
	]);

	useCommand(
		{
			key: 'baselines',
			text: t('ViewBaselineCmds'),
			iconProps: {iconName: 'History'},
			subMenuProps: {items: subcommands},
			hidden: !subcommands.length,
		},
		[subcommands],
	);

	return {
		infoForIsSuccessDialogOpen,
		creationResponse: response,
	};
};
