import {QueryResult} from '@apollo/client';
import {RegulatoryDocument, StringOperationFilterInput} from 'types';
import _ from 'lodash';
import {SetStateAction, useCallback, useEffect, useState} from 'react';
import {
	GetAuditLogEntityInfoQuery,
	GetAuditLogEntityInfoQueryVariables as EntityInfoVariables,
	GetAuditLogsQuery,
	useGetAuditLogEntityInfoLazyQuery,
	GetAuditLogsQueryVariables as AuditLogsVariables,
	useGetAuditLogsLazyQuery,
} from '../hooks/useGetRegulatoryDocumentDetails.generated';

export type RegDocQueryAuditLogs = NonNullable<GetAuditLogsQuery['auditLogs']>;
type AuditLogs = NonNullable<RegDocQueryAuditLogs['nodes']>;
export type QueryAuditLog = AuditLogs[number];

/**
 * Entity info
 */
type AuditLogsEntityInfo = NonNullable<GetAuditLogEntityInfoQuery['auditLogs']>;

type EntityInfoNodes = NonNullable<AuditLogsEntityInfo['nodes']>;

export type EntityInfo = EntityInfoNodes[number];

type IsLoading = boolean;

/**
 * Other
 */
export interface RegDocAuditLogsInfo {
	auditLogs: QueryAuditLog[];
	refetchAuditLogsAndUpdateIsLoading: () => Promise<void>;
	isLoading: IsLoading;
	setAreAuditLogsLoading: React.Dispatch<SetStateAction<IsLoading>>;
}

type AuditFieldNames =
	| 'summary'
	| 'workflowStatus'
	| 'status'
	| 'modelYear'
	| 'dateEffective'
	| 'dateExpiration'
	| 'name';

export const regDocAuditFieldNames: Record<AuditFieldNames, string> = {
	summary: '$.summary',
	workflowStatus: 'workflow.status',
	status: '$.status',
	modelYear: '$.modelYear',
	dateEffective: 'dateEffective',
	dateExpiration: 'dateExpiration',
	name: '$.name',
};

const getFieldFilter = (field: string): StringOperationFilterInput => {
	return {contains: field};
};

export const getFieldsFilter = (): AuditLogsVariables['fieldsFilter'] => {
	const fields: string[] = Object.values(regDocAuditFieldNames);
	return fields.map(getFieldFilter);
};

export const useGetRegDocAuditLogs = (
	docId: RegulatoryDocument['id'],
): RegDocAuditLogsInfo => {
	const [auditLogs, setAuditLogs] = useState<QueryAuditLog[]>([]);
	const [isLoading, setIsLoading] = useState<IsLoading>(false);

	const [getEntityInfo] = useGetAuditLogEntityInfoLazyQuery();
	const [getAuditLogs] = useGetAuditLogsLazyQuery();

	/**
	 * Entity infos
	 */
	type EntityInfoResult = QueryResult<
		GetAuditLogEntityInfoQuery,
		EntityInfoVariables
	>;

	type PossibleEntityInfo = EntityInfo | null;

	interface EntityInfoState {
		queryResult: EntityInfoResult;
		possibleRegDocEntityInfo: PossibleEntityInfo;
		shouldContinueSearching: boolean;
	}

	const getIfEntityInfoBelongsToRegDoc = (entityInfo: EntityInfo): boolean => {
		return entityInfo.entity?.id === docId;
	};

	const findEntityInfoOfRegDoc = (
		result: EntityInfoResult,
	): EntityInfoState['possibleRegDocEntityInfo'] => {
		const auditLogs: EntityInfo[] = result.data?.auditLogs?.nodes ?? [];
		type Match = EntityInfo | undefined;
		const match: Match = auditLogs.find(getIfEntityInfoBelongsToRegDoc);
		return match ?? null;
	};

	type EntityInfoResultAndMatch = Pick<
		EntityInfoState,
		'queryResult' | 'possibleRegDocEntityInfo'
	>;

	type AuditLogsResult = QueryResult<GetAuditLogsQuery, AuditLogsVariables>;

	const getIfQueryResultHasNextPage = (
		result: EntityInfoResult | AuditLogsResult,
	): boolean => {
		return Boolean(result.data?.auditLogs?.pageInfo?.hasNextPage);
	};

	const getIfShouldContinueSearchingInfos = ({
		queryResult: result,
		possibleRegDocEntityInfo,
	}: EntityInfoResultAndMatch): boolean => {
		const hasNextPage: boolean = getIfQueryResultHasNextPage(result);
		return !possibleRegDocEntityInfo && hasNextPage;
	};

	type PossibleEndCursor =
		| EntityInfoVariables['endCursor']
		| AuditLogsVariables['endCursor'];

	/**
	 * We aren't using fetchMore here because it does not provide a benefit.
	 * Specifically, we don't need to merge the entity infos into a single list.
	 */
	const getEntityInfoFromEndCursor = (
		endCursor: PossibleEndCursor,
	): Promise<EntityInfoResult> => {
		return getEntityInfo({
			variables: {endCursor, fieldsFilter: getFieldsFilter()},
		});
	};

	const getEntityInfoResultAndMatch = async (
		endCursor?: PossibleEndCursor,
	): Promise<EntityInfoResultAndMatch> => {
		const result: EntityInfoResult = await getEntityInfoFromEndCursor(
			endCursor,
		);
		return {
			queryResult: result,
			possibleRegDocEntityInfo: findEntityInfoOfRegDoc(result),
		};
	};

	const getEntityInfoState = async (
		endCursor?: PossibleEndCursor,
	): Promise<EntityInfoState> => {
		const info: EntityInfoResultAndMatch = await getEntityInfoResultAndMatch(
			endCursor,
		);
		return {
			...info,
			shouldContinueSearching: getIfShouldContinueSearchingInfos(info),
		};
	};

	interface AuditLogsState {
		auditLogs: QueryAuditLog[];
		hasNextPage: boolean;
		queryResult: AuditLogsResult;
	}

	const getEndCursorFromQueryResult = (
		state: EntityInfoState | AuditLogsState,
	): PossibleEndCursor => {
		return state.queryResult.data?.auditLogs?.pageInfo.endCursor;
	};

	const fetchEntityInfosAndUpdateState = async (
		state: EntityInfoState,
	): Promise<void> => {
		const endCursor: PossibleEndCursor = getEndCursorFromQueryResult(state);
		const newState: EntityInfoState = await getEntityInfoState(endCursor);
		_.assign(state, newState);
	};

	const addRemainingEntityInfos = async (
		state: EntityInfoState,
	): Promise<void> => {
		while (state.shouldContinueSearching) {
			// eslint-disable-next-line no-await-in-loop
			await fetchEntityInfosAndUpdateState(state);
		}
	};

	const findAuditLogEntityInfo = async (): Promise<PossibleEntityInfo> => {
		const entityInfoState: EntityInfoState = await getEntityInfoState();
		await addRemainingEntityInfos(entityInfoState);
		return entityInfoState.possibleRegDocEntityInfo;
	};

	type PossibleAuditLogsState = AuditLogsState | null;

	/**
	 * Audit logs
	 */
	const getEndCursorFromPossibleAuditLogsState = (
		prevState: PossibleAuditLogsState,
	): PossibleEndCursor => {
		return prevState ? getEndCursorFromQueryResult(prevState) : undefined;
	};

	const getAuditLogsFromPrevState = (
		entityInfoForDoc: EntityInfo,
		prevState: PossibleAuditLogsState,
	): Promise<AuditLogsResult> => {
		return getAuditLogs({
			variables: {
				endCursor: getEndCursorFromPossibleAuditLogsState(prevState),
				entityId: entityInfoForDoc.entityId,
				fieldsFilter: getFieldsFilter(),
			},
		});
	};

	const combineAuditLogs = (
		result: AuditLogsResult,
		auditLogs: QueryAuditLog[] = [],
	): QueryAuditLog[] => {
		const newAuditLogs: QueryAuditLog[] = result.data?.auditLogs?.nodes ?? [];
		return [...auditLogs, ...newAuditLogs];
	};

	const createAuditLogsStateFromResult = (
		queryResult: AuditLogsResult,
		prevAuditLogs?: QueryAuditLog[],
	): AuditLogsState => {
		return {
			queryResult,
			auditLogs: combineAuditLogs(queryResult, prevAuditLogs),
			hasNextPage: getIfQueryResultHasNextPage(queryResult),
		};
	};

	const getAuditLogsState = async (
		entityInfoForDoc: EntityInfo,
		state: PossibleAuditLogsState,
	): Promise<AuditLogsState> => {
		const result: AuditLogsResult = await getAuditLogsFromPrevState(
			entityInfoForDoc,
			state,
		);
		return createAuditLogsStateFromResult(result, state?.auditLogs);
	};

	const fetchAuditLogsAndUpdateState = async (
		entityInfoForDoc: EntityInfo,
		state: AuditLogsState,
	): Promise<void> => {
		const newState: AuditLogsState = await getAuditLogsState(
			entityInfoForDoc,
			state,
		);
		_.assign(state, newState);
	};

	const addRemainingAuditLogs = async (
		entityInfoForDoc: EntityInfo,
		state: AuditLogsState,
	): Promise<void> => {
		while (state.hasNextPage) {
			// eslint-disable-next-line no-await-in-loop
			await fetchAuditLogsAndUpdateState(entityInfoForDoc, state);
		}
	};

	const getAuditLogsFromAllPages = async (
		docEntityInfo: EntityInfo,
	): Promise<QueryAuditLog[]> => {
		const state: AuditLogsState = await getAuditLogsState(docEntityInfo, null);
		await addRemainingAuditLogs(docEntityInfo, state);
		return state.auditLogs;
	};

	const getAuditLogsIfPossible = async (): Promise<QueryAuditLog[]> => {
		/**
		 * We must find an entity info for the current reg doc so we can get the
		 * reg doc's "entityId," which the audit log uses to identify the reg doc.
		 * This is not the same as the reg doc's ID. We need the "entityId" to
		 * filter the audit logs efficiently.
		 */
		const entityInfoMatch: PossibleEntityInfo = await findAuditLogEntityInfo();
		// If we could not find a match, then there are no audit logs for this reg
		// doc
		if (!entityInfoMatch) return [];
		return getAuditLogsFromAllPages(entityInfoMatch);
	};

	const refetchAuditLogsAndUpdateIsLoading: RegDocAuditLogsInfo['refetchAuditLogsAndUpdateIsLoading'] =
		useCallback(async () => {
			setIsLoading(true);
			const auditLogs: QueryAuditLog[] = await getAuditLogsIfPossible();
			setAuditLogs(auditLogs);
			setIsLoading(false);
		}, [docId]);

	useEffect(() => {
		refetchAuditLogsAndUpdateIsLoading();
	}, [docId]);

	return {
		auditLogs,
		refetchAuditLogsAndUpdateIsLoading,
		isLoading,
		setAreAuditLogsLoading: setIsLoading,
	};
};
