import {Pivot, PivotItem, PrimaryButton, Stack} from '@fluentui/react';
import {ControlledTextField} from 'components/hookForms';
import {LoadWrapper} from 'components/LoadWrapper';
import React from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {
	ParagraphResultsList,
	RegulationResultsList,
	RegulatoryDocumentResultsList,
} from './components';
import {useFullTextSearchLazyQuery} from './hooks';

type SearchInput = {
	searchText: string;
};

type SearchEntity = 'regulations' | 'regulatoryDocuments' | 'paragraphs';

export const SearchPage: React.FC = () => {
	const {t} = useTranslation('features/search');

	const [searchParams, setSearchParams] = useSearchParams();

	const text = searchParams.get('text') || '';
	const entity: 'regulations' | 'regulatoryDocuments' | 'paragraphs' =
		(searchParams.get('entity') as any) || 'regulations';

	const [runSearch, {data, loading}] = useFullTextSearchLazyQuery();
	const {regulations, regulatoryDocuments, paragraphs} = React.useMemo(
		() =>
			data?.fullTextSearch || {
				regulations: [],
				regulatoryDocuments: [],
				paragraphs: [],
			},
		[data],
	);

	const {control, handleSubmit, reset} = useForm<SearchInput>({
		defaultValues: {searchText: text},
	});

	React.useEffect(() => {
		if (text !== '') {
			reset({searchText: text});
			runSearch({
				variables: {
					searchString: text,
				},
			});
		}
	}, [text]);

	const onPivotItemClick = React.useCallback(
		(item: PivotItem | undefined) => {
			if (item?.props.itemKey) {
				searchParams.set('entity', item.props.itemKey as SearchEntity);
				setSearchParams(searchParams);
			}
		},
		[text, searchParams],
	);

	const onSearch = React.useCallback(() => {
		handleSubmit(data => {
			const {searchText} = data;
			if (searchText.length > 2) {
				searchParams.set('text', searchText);
				setSearchParams(searchParams);
				runSearch({
					variables: {
						searchString: searchText,
					},
				});
			}
		})();
	}, [searchParams.entries]);

	return (
		<Stack tokens={{childrenGap: 8}}>
			<Stack styles={{root: {marginLeft: 20}}}>
				<h3>{t('SearchFunction')}</h3>
				<Stack horizontal tokens={{childrenGap: 8}}>
					<ControlledTextField
						styles={{root: {width: 400}}}
						control={control}
						name={'searchText'}
					/>
					<PrimaryButton onClick={onSearch}>{t('Search')}</PrimaryButton>
				</Stack>
			</Stack>
			<Pivot onLinkClick={onPivotItemClick} defaultSelectedKey={entity}>
				<PivotItem headerText={t('Regulations')} itemKey='regulations'>
					<LoadWrapper loading={loading}>
						<RegulationResultsList regulations={regulations} />
					</LoadWrapper>
				</PivotItem>
				<PivotItem
					headerText={t('RegulatoryDocuments')}
					itemKey='regulatoryDocuments'
				>
					<LoadWrapper loading={loading}>
						<RegulatoryDocumentResultsList
							regulatoryDocuments={regulatoryDocuments}
						/>
					</LoadWrapper>
				</PivotItem>
				<PivotItem headerText={t('Paragraphs')} itemKey='paragraphs'>
					<LoadWrapper loading={loading}>
						<ParagraphResultsList paragraphs={paragraphs} />
					</LoadWrapper>
				</PivotItem>
			</Pivot>
		</Stack>
	);
};
