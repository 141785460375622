import {
	DetailsList,
	FontIcon,
	mergeStyleSets,
	PersonaSize,
	Theme,
	useTheme,
	Text,
	IDetailsHeaderProps,
	IRenderFunction,
} from '@fluentui/react';
import {
	renderDate,
	renderPersona,
	renderRegulatoryDocumentStatus,
} from 'components/EntityList/ColumnRenderers';
import {LoadWrapper} from 'components/LoadWrapper';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {RegulatoryDocument} from 'types';
import {useGetArchivedRegulatoryDocumentsQuery} from './hooks/GetArchivedRegulatoryDocuments.generated';
import {RegDocsTooltipTranslationProvider} from 'features/RegulatoryDocuments/components/RegDocTooltipTranslationProvider';
import {
	ProviderThatEnablesGettingTooltipsFromContext,
	commonEntityNamespacesWithTooltipText,
	useTooltipTranslation,
	getRendererForHeaderWithTooltips,
} from 'features/localizedTooltips';

const RegulatoryDocumentArchivePage: React.FC = () => {
	const {t} = useTranslation('features/archive', {
		keyPrefix: 'RegulatoryDocumentArchive',
	});
	const {t: tEnum} = useTranslation('common/enums');
	const theme = useTheme();
	const classNames = getClassNames(theme);
	const {loading, data} = useGetArchivedRegulatoryDocumentsQuery();

	const regulatoryDocuments = React.useMemo(
		() => data?.regulatoryDocuments?.edges?.map(e => e.node) ?? [],
		[data],
	);

	const {t: translateForPage} = useTooltipTranslation(
		'features/Archive/regulatoryDocumentArchivePage',
	);

	const {t: translateForWorkflow} = useTooltipTranslation(
		commonEntityNamespacesWithTooltipText.workflows,
	);

	const columns = React.useMemo(
		() => [
			{
				key: 'file',
				name: t('FileType'),
				className: classNames.fileIconCell,
				iconClassName: classNames.fileIconHeaderIcon,
				ariaLabel: 'Column operations for File type',
				iconName: 'Page',
				isIconOnly: true,
				fieldName: 'fileType',
				minWidth: 16,
				maxWidth: 16,
				tooltipHostProps: {content: translateForPage('fileType')},
				onRender: (item: RegulatoryDocument) =>
					item.document ? (
						<FontIcon
							aria-label='Page'
							iconName='PDF'
							className={classNames.fileIconHeaderIcon}
						/>
					) : (
						<></>
					),
			},
			{
				key: 'name',
				name: t('Version'),
				fieldName: 'name',
				isMultiline: true,
				minWidth: 150,
				maxWidth: 150,
				isResizable: true,
				sortable: true,
			},
			{
				key: 'summary',
				name: t('Summary'),
				fieldName: 'summary',
				isMultiline: true,
				minWidth: 150,
				maxWidth: 150,
				isResizable: true,
				sortable: true,
			},
			{
				key: 'status',
				name: t('Status'),
				fieldName: 'status',
				filterable: true,
				getFilterLabel: (item: RegulatoryDocument) =>
					tEnum(`RegulatoryDocumentStatus.${item}`),
				isMultiline: true,
				minWidth: 150,
				maxWidth: 150,
				isResizable: true,
				onRender: renderRegulatoryDocumentStatus(),
				sortable: true,
			},
			{
				key: 'createdAt',
				name: t('CreatedAt'),
				fieldName: 'createdAt',
				filterable: true,
				isMultiline: false,
				minWidth: 100,
				maxWidth: 100,
				isResizable: true,
				onRender: renderDate(),
				sortable: true,
			},
			{
				key: 'createdBy',
				name: t('CreatedBy'),
				fieldName: 'createdBy',
				filterable: true,
				minWidth: 150,
				maxWidth: 200,
				isResizable: true,
				onRender: renderPersona({size: PersonaSize.size8}),
			},
			{
				key: 'modifiedAt',
				name: t('ModifiedAt'),
				fieldName: 'modifiedAt',
				filterable: true,
				isMultiline: false,
				minWidth: 100,
				maxWidth: 100,
				isResizable: true,
				onRender: renderDate(),
				sortable: true,
			},
			{
				key: 'modifiedBy',
				name: t('ModifiedBy'),
				fieldName: 'modifiedBy',
				filterable: true,
				minWidth: 150,
				maxWidth: 200,
				isResizable: true,
				onRender: renderPersona({size: PersonaSize.size8}),
			},
			{
				key: 'message',
				name: t('WorkflowMessage'),
				fieldName: 'message',
				minWidth: 150,
				tooltipHostProps: {content: translateForWorkflow('message')},
				onRender: (item: RegulatoryDocument) => (
					<Text>{item.workflow.message}</Text>
				),
			},
		],
		[t],
	);

	const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> =
		getRendererForHeaderWithTooltips(false);

	return (
		<LoadWrapper loading={loading}>
			<RegDocsTooltipTranslationProvider>
				<ProviderThatEnablesGettingTooltipsFromContext>
					<DetailsList
						items={regulatoryDocuments}
						columns={columns}
						onRenderDetailsHeader={onRenderDetailsHeader}
					/>
				</ProviderThatEnablesGettingTooltipsFromContext>
			</RegDocsTooltipTranslationProvider>
		</LoadWrapper>
	);
};

const getClassNames = (theme: Theme) =>
	mergeStyleSets({
		fileIconHeaderIcon: {
			padding: 0,
			fontSize: '16px',
			color: theme.palette.neutralPrimary,
		},
		fileIconCell: {
			textAlign: 'center',
			selectors: {
				'&:before': {
					content: '.',
					display: 'inline-block',
					verticalAlign: 'middle',
					height: '100%',
					width: '0px',
					visibility: 'hidden',
				},
			},
		},
	});

export default RegulatoryDocumentArchivePage;
