import {
	CheckboxVisibility,
	DefaultButton,
	DetailsList,
	DetailsListLayoutMode,
	Dialog,
	DialogFooter,
	DialogType,
	IDetailsHeaderProps,
	IDialogContentProps,
	IRenderFunction,
	PrimaryButton,
	ScrollablePane,
	Stack,
	Text,
	Toggle,
	useTheme,
} from '@fluentui/react';
import {useBoolean} from '@fluentui/react-hooks';
import {useSelection} from 'hooks';
import React from 'react';
import {
	compareParagraphs,
	CompareResult,
	IComparedItem,
} from '../../compareDocuments/compareDocuments';
import {CompareVersionPanelTooltipTranslationProvider} from '../CompareVersionPanelTooltipTranslationProvider';
import {
	ColumnWithTooltipOptions,
	ProviderThatEnablesGettingTooltipsFromContext,
	getRendererForHeaderWithTooltips,
} from 'features/localizedTooltips';
import {useComparisonUpdate} from '../_useComparisonUpdate';
import {ParagraphItem} from './ParagraphItem';
import {useTranslationsOfCompareVersionPanel} from '../CompareVersionPanel.utils';
import {ResultTypeFilterOption} from './ResultTypeFilterOption';
import {RegDoc} from './ComparisonComponent.types';

const comparisonListStyles = {
	root: {
		overflowX: 'hidden',
	},
};

const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> =
	getRendererForHeaderWithTooltips(true);

export interface ComparisonComponentProps {
	oldVersion: RegDoc;
	newVersion: RegDoc;
	onDismiss: () => void;
}

export const ComparisonComponent: React.FC<ComparisonComponentProps> = ({
	oldVersion,
	newVersion,
	onDismiss,
}) => {
	const {t} = useTranslationsOfCompareVersionPanel();
	const theme = useTheme();

	const [selection, selectedItems] = useSelection<IComparedItem>();
	const {updateHandler, updatedTextItems} = useComparisonUpdate(
		selectedItems,
		newVersion,
	);

	const [compareResult, setCompareResult] = React.useState<IComparedItem[]>([]);
	const [showDeleted, {toggle: toggleDeleted}] = useBoolean(false);
	const [showNew, {toggle: toggleNew}] = useBoolean(false);
	const [showChanged, {toggle: toggleChanged}] = useBoolean(false);
	const [showEqual, {toggle: toggleEqual}] = useBoolean(false);
	const [showInReview, {toggle: toggleInReview}] = useBoolean(false);

	const [showMetadata, {toggle: toggleMetadata}] = useBoolean(true);

	const [hideDialog, {toggle: toggleHideDialog}] = useBoolean(true);

	const dialogContentProps: IDialogContentProps = {
		type: DialogType.normal,
		title: t('MetadataDialogTitle'),
		closeButtonAriaLabel: 'Close',
		subText: t('MetadataDialogSubtext'),
	};

	React.useEffect(() => {
		setCompareResult(
			compareParagraphs(oldVersion.paragraphs, newVersion.paragraphs),
		);

		return () => setCompareResult([]);
	}, [oldVersion, newVersion]);

	const onCopyMetadataClick = React.useCallback(() => {
		updateHandler();
		toggleHideDialog();
		onDismiss();
	}, [selectedItems]);

	const comparisonItems = React.useMemo(() => {
		return compareResult.filter(item => {
			// If no filters are selected
			if (
				!showDeleted &&
				!showNew &&
				!showChanged &&
				!showEqual &&
				!showInReview
			) {
				return true;
			}

			if (!showDeleted && item.resultType === CompareResult.Deleted) {
				return false;
			}

			if (!showNew && item.resultType === CompareResult.New) {
				return false;
			}

			if (!showChanged && item.resultType === CompareResult.Changed) {
				return false;
			}

			if (!showEqual && item.resultType === CompareResult.Equal) {
				return false;
			}

			if (!showInReview && item.resultType === CompareResult.InReview) {
				return false;
			}

			return true;
		});
	}, [
		compareResult,
		showDeleted,
		showNew,
		showChanged,
		showEqual,
		showInReview,
	]);

	const ConfirmMetadataDialog = () => (
		<Dialog
			hidden={hideDialog}
			onDismiss={toggleHideDialog}
			dialogContentProps={dialogContentProps}
		>
			<Text>{t('MetadataDialogMessage')}:</Text>
			{updatedTextItems.map(textItem => (
				<div key={textItem}>- {textItem}</div>
			))}
			<DialogFooter>
				<PrimaryButton onClick={onCopyMetadataClick} text={t('Submit')} />
				<DefaultButton onClick={toggleHideDialog} text={t('Cancel')} />
			</DialogFooter>
		</Dialog>
	);

	const columns: ColumnWithTooltipOptions[] = [
		{
			key: 'oldVersion',
			name: oldVersion.name,
			minWidth: 100,
			flexGrow: 50,
			isResizable: true,
			tooltipHostProps: {translationKey: 'oldVersion'},
			onRender: (item: IComparedItem) => (
				<ParagraphItem
					comparedParagraph={item.oldParagraph}
					resultType={item.resultType}
					showComparisonMark={true}
					showMetadata={showMetadata}
					theme={theme}
				/>
			),
		},
		{
			key: 'newVersion',
			name: newVersion.name,
			minWidth: 100,
			flexGrow: 50,
			isResizable: true,
			tooltipHostProps: {translationKey: 'newVersion'},
			onRender: (item: IComparedItem) => (
				<ParagraphItem
					comparedParagraph={item.newParagraph}
					resultType={item.resultType}
					showComparisonMark={false}
					showMetadata={showMetadata}
					theme={theme}
				/>
			),
		},
	];

	return (
		<Stack>
			<ConfirmMetadataDialog />
			<Stack horizontal horizontalAlign='space-between'>
				<Stack horizontal>
					<ResultTypeFilterOption
						resultType={CompareResult.Deleted}
						labelTranslationKey='Deleted'
						onChangeCheckbox={toggleDeleted}
					/>
					<ResultTypeFilterOption
						resultType={CompareResult.New}
						labelTranslationKey='Added'
						onChangeCheckbox={toggleNew}
					/>
					<ResultTypeFilterOption
						resultType={CompareResult.Changed}
						labelTranslationKey='TextChange'
						onChangeCheckbox={toggleChanged}
					/>
					<ResultTypeFilterOption
						resultType={CompareResult.Equal}
						labelTranslationKey='NoChange'
						onChangeCheckbox={toggleEqual}
					/>
					<ResultTypeFilterOption
						resultType={CompareResult.InReview}
						labelTranslationKey='InReview'
						onChangeCheckbox={toggleInReview}
					/>
				</Stack>

				<Stack horizontal tokens={{childrenGap: 32}}>
					<Toggle
						label={t('ShowMetadata')}
						inlineLabel
						checked={showMetadata}
						onChange={toggleMetadata}
					/>
					<PrimaryButton
						text={t('CopyMetadata')}
						onClick={toggleHideDialog}
						styles={{root: {marginRight: 15}}}
						disabled={selectedItems.length === 0}
					/>
				</Stack>
			</Stack>
			<div style={{position: 'relative', height: 'calc(100vh - 105px)'}}>
				<ScrollablePane>
					<CompareVersionPanelTooltipTranslationProvider>
						<ProviderThatEnablesGettingTooltipsFromContext>
							<DetailsList
								items={comparisonItems}
								layoutMode={DetailsListLayoutMode.fixedColumns}
								checkboxVisibility={CheckboxVisibility.always}
								styles={comparisonListStyles}
								selection={selection}
								onRenderDetailsHeader={onRenderDetailsHeader}
								columns={columns}
							/>
						</ProviderThatEnablesGettingTooltipsFromContext>
					</CompareVersionPanelTooltipTranslationProvider>
				</ScrollablePane>
			</div>
		</Stack>
	);
};
