import React, {useEffect, useMemo, useState} from 'react';
import {BaselineCmdsInfo} from '../baselines/baselineCmds';
import {
	Dialog,
	DialogFooter,
	DialogType,
	IDialogContentProps,
	PrimaryButton,
	TextField,
} from '@fluentui/react';
import {useVehicleProjectsTranslation} from 'features/VehicleProjects/hooks/vehicleProjectsTranslationHooks';
import {
	GetBaselinesByModelAndSeriesDocument,
	useRenameBaselineMutation,
} from '../hooks/baseline.generated';

interface Props {
	creationResponse: BaselineCmdsInfo['creationResponse'];
	infoForIsOpen: BaselineCmdsInfo['infoForIsSuccessDialogOpen'];
}

export function BaselineCreationSuccessDialog({
	creationResponse,
	infoForIsOpen: [isOpen, {setFalse}],
}: Props): JSX.Element {
	const {t} = useVehicleProjectsTranslation({
		keyPrefix: 'BaselineCreationSuccessDialog',
	});

	const [renameBaseline] = useRenameBaselineMutation();
	const id = creationResponse.data?.createBaseLineByModelAndSeries?.id ?? '';
	const oldName = creationResponse.data?.createBaseLineByModelAndSeries?.name;
	const [name, setName] = useState(oldName ?? '');

	const onTitleChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string,
	) => {
		setName(newValue ?? '');
	};

	useEffect(() => {
		if (!creationResponse.loading) {
			// Perform your action here

			setName(oldName ?? '');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [creationResponse.loading]);

	const handleClose = async () => {
		try {
			await renameBaseline({
				variables: {id, name},
				refetchQueries: [GetBaselinesByModelAndSeriesDocument],
			});
		} catch (error) {
			console.error('Failed to rename baseline:', error);
		}

		setFalse();
	};

	const dialogContentProps: IDialogContentProps = useMemo(() => {
		return {
			type: DialogType.normal,
			title: t('Title'),
			styles: {title: {wordBreak: 'break-word'}},
		};
	}, [t]);

	const isNameEmpty = !name;

	return (
		<Dialog
			hidden={!isOpen}
			onDismiss={setFalse}
			dialogContentProps={dialogContentProps}
		>
			<TextField
				value={name}
				onChange={onTitleChange}
				multiline
				required
				styles={{fieldGroup: {minHeight: '200px'}}}
				errorMessage={isNameEmpty ? t('EmptyNameError') : undefined}
			/>
			<DialogFooter>
				<PrimaryButton onClick={handleClose} disabled={isNameEmpty}>
					{t('CloseBtn')}
				</PrimaryButton>
			</DialogFooter>
		</Dialog>
	);
}
