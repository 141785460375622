import {RequirementFromDetailsPage} from '../RequirementDetailsPage/requirementDetailsPage.types';
import {RequirementFromListPage} from '../requirementPage.types';

export const useRequirementsVersions = () => {
	const getInitialVersion = (): string => 'V1.0';

	const getVersionMatches = (
		currVersion: string,
	): {succeeded: boolean; major: number; minor: number} => {
		const regexp = /V(\d+)\.(\d+)/g;
		const matches = [...currVersion.matchAll(regexp)];
		if (matches.length === 1 && matches[0].length === 3) {
			const major = Number(matches[0][1]);
			const minor = Number(matches[0][2]);
			return {succeeded: true, major, minor};
		}

		console.error(`Invalid version '${currVersion}'. Cannot increment`);
		return {succeeded: false, major: 1, minor: 0};
	};

	const incrementMinorVersion = (currVersion: string): string => {
		const versions = getVersionMatches(currVersion);
		if (versions.succeeded) {
			return `V${versions.major}.${versions.minor + 1}`;
		}

		return currVersion;
	};

	const incrementMajorVersion = (currVersion: string): string => {
		const versions = getVersionMatches(currVersion);
		if (versions.succeeded) {
			return `V${versions.major + 1}.0`;
		}

		return currVersion;
	};

	const hasEditableChildren = (
		requirement?: RequirementFromDetailsPage | RequirementFromListPage,
	) => {
		if (!requirement || (requirement?.childrenRefs?.length || 0) === 0)
			return false;

		const reqMatches = getVersionMatches(
			requirement?.version || getInitialVersion(),
		);
		const myMajorVersion = reqMatches.major;

		const childIndex = requirement.childrenRefs?.findIndex(child => {
			if (child?.version) {
				const {major} = getVersionMatches(child.version);
				return major === myMajorVersion;
			}

			return false;
		});
		return childIndex !== -1;
	};

	return {
		getInitialVersion,
		incrementMinorVersion,
		incrementMajorVersion,
		hasEditableChildren,
	};
};
