import React, {useMemo} from 'react';
import {ProjectDetailsPageParams} from '../vehicleProjectPagePaths';
import {IBreadcrumbItem} from '@fluentui/react';
import {
	NavigateToBaselinesPage,
	useNavigateToBaselinesPage,
} from '../BaselinesPage/BaselinesPage.utils';
import {useBreadcrumbsOfBaselinesPage} from '../BaselinesPage/BaselinesPage';
import {useBaselinesTranslations} from './baselines.utils';
import {AutoResizingBreadcrumbs} from 'components/AutoResizingBreadcrumbs';
import {Baseline} from 'types';

type BaselineName = Baseline['name'];

export type ExtraBreadcrumbFields = Partial<IBreadcrumbItem>;

interface FieldsToCreateBreadcrumbs {
	params: ProjectDetailsPageParams;
	baselineName: BaselineName;
	pageBreadcrumbFields?: ExtraBreadcrumbFields;
}

const useBreadcrumbs = ({
	params,
	baselineName,
	pageBreadcrumbFields,
}: FieldsToCreateBreadcrumbs): IBreadcrumbItem[] => {
	const {t} = useBaselinesTranslations();
	const navigateToBaselinesPage: NavigateToBaselinesPage =
		useNavigateToBaselinesPage(params);

	const fieldsForBreadcrumbOfBaselinesPage =
		useMemo((): ExtraBreadcrumbFields => {
			return {onClick: navigateToBaselinesPage};
		}, [navigateToBaselinesPage]);

	const breadcrumbsOfBaselinesPage: IBreadcrumbItem[] =
		useBreadcrumbsOfBaselinesPage(params, fieldsForBreadcrumbOfBaselinesPage);

	return useMemo((): IBreadcrumbItem[] => {
		const createBaselineBreadcrumb = (): IBreadcrumbItem => {
			return {
				key: 'breadcrumb-details-page',
				/**
				 * We show a default value for the baseline's name in case the name is an
				 * empty string.
				 */
				text: baselineName || t('DefaultBaselineName'),
				...pageBreadcrumbFields,
			};
		};

		const baselineBreadcrumb: IBreadcrumbItem = createBaselineBreadcrumb();
		return [...breadcrumbsOfBaselinesPage, baselineBreadcrumb];
	}, [baselineName, breadcrumbsOfBaselinesPage, pageBreadcrumbFields, t]);
};

export type PropsOfBaselineBreadcrumbs = FieldsToCreateBreadcrumbs;

/**
 * We don't show the breadcrumbs when loading so that they don't shift after
 * query loads. This can happen when the baseline's name is too big.
 */
export function BaselineBreadcrumbs(props: PropsOfBaselineBreadcrumbs) {
	const breadcrumbs: IBreadcrumbItem[] = useBreadcrumbs(props);

	return <AutoResizingBreadcrumbs items={breadcrumbs} />;
}
