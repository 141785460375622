import {IColumn, PersonaSize} from '@fluentui/react';
import {EntityList} from 'components';
import {
	renderBoolean,
	renderDate,
	renderPersona,
} from 'components/EntityList/ColumnRenderers';
import {useEntityContext} from 'components/EntityPage/EntityContext';
import {LoadWrapper} from 'components/LoadWrapper';
import {useSelection} from 'hooks';
import {DateTimeFormat} from 'i18n/localeDateFormat';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {GdprRule} from 'types';
import {useGetGdprRulesQuery} from '../hooks/gdprRules.generated';
import {GdrpRulesTooltipTranslationProvider} from 'features/localizedTooltips';

export const RulesList: React.FC = () => {
	const {t} = useTranslation('features/adminsection', {
		keyPrefix: 'AdminDataProtectionPage',
	});

	const {setSelectedItems} = useEntityContext<GdprRule>();

	const [selection] = useSelection<GdprRule>({
		onSelectionChanged(selectedItems) {
			setSelectedItems?.(selectedItems);
		},
	});

	const {data, loading} = useGetGdprRulesQuery();

	const gdprRules = React.useMemo(() => data?.gdprRules || [], [data]);

	const columns: IColumn[] = React.useMemo(
		() => [
			{
				key: 'name',
				name: t('GdprRuleName'),
				fieldName: 'name',
				minWidth: 100,
				maxWidth: 200,
			},
			{
				key: 'deletionPeriod',
				name: t('DeletionPeriod'),
				fieldName: 'deletionPeriod',
				minWidth: 100,
				maxWidth: 200,
			},
			{
				key: 'anonymize',
				name: t('Anonymize'),
				fieldName: 'anonymize',
				minWidth: 100,
				maxWidth: 200,
				onRender: renderBoolean(),
			},
			{
				key: 'createdBy',
				name: t('CreatedBy'),
				fieldName: 'createdBy',
				minWidth: 100,
				maxWidth: 150,
				isResizable: true,
				onRender: renderPersona({size: PersonaSize.size8}),
			},
			{
				key: 'createdAt',
				name: t('CreatedAt'),
				fieldName: 'createdAt',
				minWidth: 100,
				maxWidth: 150,
				isResizable: true,
				onRender: renderDate(DateTimeFormat.DateShort),
			},
		],
		[],
	);

	return (
		<LoadWrapper loading={loading}>
			<GdrpRulesTooltipTranslationProvider>
				<EntityList selection={selection} items={gdprRules} columns={columns} />
			</GdrpRulesTooltipTranslationProvider>
		</LoadWrapper>
	);
};
