import {Baseline} from 'types';
import {createBaselinesPagePath} from '../BaselinesPage/BaselinesPage.utils';
import {
	ProjectDetailsPageParams,
	encodeGenAndModelSeries,
} from '../vehicleProjectPagePaths';
import {createNamespaceForVehicleProjects} from '../hooks/vehicleProjectsTranslationHooks';
import translations from 'locales/features/VehicleProjects/baselineDetailsPage.de.json';
import {UseTranslationOptions, useTranslation} from 'react-i18next';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import {useCallback} from 'react';

export interface FieldsToCreatePathOfBaselineDetailsPage
	extends ProjectDetailsPageParams {
	baselineId: Baseline['id'];
}

export const createPathOfBaselineDetailsPage = (
	fields: FieldsToCreatePathOfBaselineDetailsPage,
): string => {
	const baselinesPagePath: string = createBaselinesPagePath(fields);
	return `${baselinesPagePath}/${fields.baselineId}`;
};

type KeyOfTranslations = keyof typeof translations;

export const useBaselineDetailsPageTranslations = (
	options?: UseTranslationOptions<KeyOfTranslations>,
) => {
	const namespace: string = createNamespaceForVehicleProjects(
		'baselineDetailsPage',
	);
	return useTranslation(namespace, options);
};

export type NavigateToBaselineDetailsPage = (
	baselineId: Baseline['id'],
) => void;

export const useNavigateToBaselineDetailsPage = (
	params: ProjectDetailsPageParams,
): NavigateToBaselineDetailsPage => {
	const navigate: NavigateFunction = useNavigate();

	const navigateToDetailsPage: NavigateToBaselineDetailsPage = useCallback(
		baselineId => {
			const createPath = (): string => {
				const encodedGenAndModel: ProjectDetailsPageParams =
					encodeGenAndModelSeries(params);
				return createPathOfBaselineDetailsPage({
					...encodedGenAndModel,
					baselineId,
				});
			};

			const path: string = createPath();
			navigate(path);
		},
		[navigate, params],
	);

	return navigateToDetailsPage;
};
