import {
	DetailsList,
	FontIcon,
	mergeStyleSets,
	PersonaSize,
	Theme,
	useTheme,
	Text,
	IDetailsHeaderProps,
	IRenderFunction,
} from '@fluentui/react';

import {LoadWrapper} from 'components/LoadWrapper';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {
	useGetPogisDocumentsQuery,
	useCreatePogisDocumentMutation,
	useUpdatePogisDocumentMutation,
	CreatePogisDocumentDocument,
	GetPogisDocumentsDocument,
} from './hooks/PogisDocuments.generated';
import {
	ArrayItemWithName,
	FieldsForAttachmentColumn,
	renderArrayField,
	renderAttachments,
	renderAttachmentsPogis,
	renderLinkField,
	renderPogisDate,
} from 'components/EntityList/ColumnRenderers';
import {PogisDocumentsTooltipTranslationProvider} from './components/PogisDocumentTranslationTooltipProvider';
import {getRendererForHeaderWithTooltips} from 'features/localizedTooltips';
import {EntityPage} from 'components';
import {PogisDate, PogisDateInput, PogisDocument} from 'types';
import {EntityContextProvider} from 'components/EntityPage/EntityContext';
import {RenderAdditionalFormElements} from 'components/EntityPage/EntityFormPanels';
import PogisDocumentFormelements from './components/PogisDocumentFormElements';
import {useUserContext} from 'authentication/UserContext';

const PogisDocumentPage: React.FC = () => {
	const {t} = useTranslation('features/pogisdocuments', {
		keyPrefix: 'PogisDocumentsList',
	});

	const [createPogisDocumentMutation] = useCreatePogisDocumentMutation();
	const [updatePogisDocumentMutation] = useUpdatePogisDocumentMutation();

	const {isAdmin} = useUserContext();
	const {loading, data} = useGetPogisDocumentsQuery();
	const pogisDocuments = React.useMemo(
		() => data?.pogisDocuments ?? [],
		[data],
	);

	const createPogisDocument = React.useCallback(
		(pogisDocument: PogisDocument) => {
			const input = mapInput(pogisDocument);
			createPogisDocumentMutation({
				variables: {
					input,
				},
			});
		},
		[],
	);

	const updatePogisDocument = React.useCallback(
		(pogisDocument: PogisDocument) => {
			const input = mapInput(pogisDocument);
			updatePogisDocumentMutation({
				variables: {
					input: {
						id: pogisDocument.id,
						...input,
					},
				},
				refetchQueries: [GetPogisDocumentsDocument],
			});
		},
		[],
	);

	const columns = React.useMemo(
		() => [
			{
				key: 'pogisId',
				name: t('pogisId'),
				fieldName: 'pogisId',
				filterable: true,
				isMultiline: false,
				minWidth: 100,
				maxWidth: 100,
				isResizable: true,
				sortable: true,
			},
			{
				key: 'keywords',
				name: t('keywords'),
				fieldName: 'keywords',
				getFieldValue: (item: any) => item.keywords,
				getFilterKey: (item: any) => item,
				getFilterLabel: (item: any) => item,
				filterable: true,
				isMultiline: true,
				minWidth: 100,
				maxWidth: 150,
				isResizable: true,
				sortable: true,
				onRender: renderArrayField(),
			},
			{
				key: 'markets',
				name: t('markets'),
				fieldName: 'markets',
				getFieldValue: (item: any) => item.markets,
				getFilterKey: (item: any) => item,
				getFilterLabel: (item: any) => item,
				filterable: true,
				isMultiline: true,
				minWidth: 100,
				maxWidth: 150,
				isResizable: true,
				sortable: true,
				onRender: renderArrayField(),
			},
			{
				key: 'regulationStatus',
				name: t('regulationStatus'),
				fieldName: 'regulationStatus',
				filterable: true,
				isMultiline: false,
				minWidth: 100,
				maxWidth: 150,
				isResizable: true,
				sortable: true,
			},
			{
				key: 'shortInfo',
				name: t('shortInfo'),
				fieldName: 'shortInfo',
				isMultiline: true,
				minWidth: 100,
				maxWidth: 150,
				isResizable: true,
				sortable: true,
			},
			{
				key: 'dates',
				name: t('dates'),
				fieldName: 'dates',
				isMultiline: true,
				minWidth: 100,
				maxWidth: 150,
				isResizable: true,
				sortable: true,
				onRender: renderPogisDate(),
			},
			{
				key: 'standards',
				name: t('standards'),
				fieldName: 'standards',
				getFieldValue: (item: any) => item.standards,
				getFilterKey: (item: any) => item,
				getFilterLabel: (item: any) => item,
				filterable: true,
				isMultiline: true,
				minWidth: 100,
				maxWidth: 150,
				isResizable: true,
				sortable: true,
				onRender: renderArrayField(),
			},
			{
				key: 'associations',
				name: t('associations'),
				fieldName: 'associations',
				getFieldValue: (item: any) => item.associations,
				getFilterKey: (item: any) => item,
				getFilterLabel: (item: any) => item,
				filterable: true,
				isMultiline: true,
				minWidth: 100,
				maxWidth: 150,
				isResizable: true,
				sortable: true,
				onRender: renderArrayField(),
			},
			{
				key: 'editor',
				name: t('editor'),
				fieldName: 'editor',
				filterable: true,
				isMultiline: false,
				minWidth: 100,
				maxWidth: 150,
				isResizable: true,
				sortable: true,
			},
			{
				key: 'relatedDocuments',
				name: t('relatedDocuments'),
				fieldName: 'relatedDocuments',
				getFieldValue: (item: any) => item.relatedDocuments,
				getFilterKey: (item: any) => item,
				getFilterLabel: (item: any) => item,
				filterable: true,
				isMultiline: true,
				minWidth: 100,
				maxWidth: 150,
				isResizable: true,
				sortable: true,
				onRender: renderArrayField(),
			},
			{
				key: 'summaryAttachments',
				name: t('summaryAttachments'),
				fieldName: 'summaryAttachments',
				isMultiline: true,
				minWidth: 100,
				maxWidth: 150,
				isResizable: true,
				sortable: true,
				onRender: renderAttachmentsPogis(),
			},
			{
				key: 'otherAttachments',
				name: t('otherAttachments'),
				fieldName: 'otherAttachments',
				isMultiline: true,
				minWidth: 100,
				maxWidth: 150,
				isResizable: true,
				sortable: true,
				onRender: renderAttachmentsPogis(),
			},
			{
				key: 'docStatus',
				name: t('docStatus'),
				fieldName: 'docStatus',
				isMultiline: false,
				minWidth: 100,
				maxWidth: 150,
				isResizable: true,
				sortable: true,
			},
			{
				key: 'linkVersion',
				name: t('linkVersion'),
				fieldName: 'linkVersion',
				isMultiline: true,
				minWidth: 200,
				maxWidth: 250,
				isResizable: true,
				sortable: true,
				onRender: renderLinkField(),
			},
		],
		[t],
	);

	const renderFormElements: RenderAdditionalFormElements<
		PogisDocument
	> = control => {
		return <PogisDocumentFormelements control={control} />;
	};

	return (
		<EntityContextProvider>
			<LoadWrapper loading={loading}>
				<PogisDocumentsTooltipTranslationProvider>
					<EntityPage
						items={pogisDocuments as any}
						entityDisplayName='POGIS Documents'
						createEntity={createPogisDocument}
						updateEntity={updatePogisDocument}
						deleteEntity={null as any}
						hideCreate={true}
						hideDelete={true}
						additionalColumns={columns}
						withNameColumn={false}
						disableEdit={() => !isAdmin}
						withNameColumnInPanel={false}
						renderAdditionalFormElements={renderFormElements}
					/>
				</PogisDocumentsTooltipTranslationProvider>
			</LoadWrapper>
		</EntityContextProvider>
	);
};

export function mapInput(pogisDocument: PogisDocument) {
	function getDateInput(dates: PogisDate[]): PogisDateInput[] {
		return dates.map(item => {
			const {__typename, ...rest} = item;
			return rest;
		});
	}

	return {
		pogisId: pogisDocument.pogisId,
		keywords: pogisDocument.keywords,
		markets: pogisDocument.markets,
		regulationStatus: pogisDocument.regulationStatus,
		shortInfo: pogisDocument.shortInfo,
		dates: getDateInput(pogisDocument.dates),
		standards: pogisDocument.standards,
		associations: pogisDocument.associations,
		editor: pogisDocument.editor,
		docStatus: pogisDocument.docStatus,
		relatedDocuments: pogisDocument.relatedDocuments,
		linkVersion: pogisDocument.linkVersion,
	};
}

export default PogisDocumentPage;
