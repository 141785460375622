import React, {useMemo} from 'react';
import {IIconProps} from '@fluentui/react';
import {useTranslation} from 'react-i18next';
import {NAMESPACE_OF_BASELINE_CHANGES} from './BaselineComparisonPage.utils';
import {useCommand} from '../../../hooks';
import {useCallback} from 'react';

import {FetchResult} from '@apollo/client';

import {
	ExportBaselineRequirementsMutation,
	useExportBaselineRequirementsMutation,
} from '../hooks/baseline.generated';

interface Props {
	baselineId: string;
	selectedRequirements: any[];
	children?: React.ReactNode; // Add this line
}

export function DownloadButton({
	selectedRequirements,
	baselineId,
}: Props): JSX.Element {
	const {t} = useTranslation(NAMESPACE_OF_BASELINE_CHANGES);
	const [exportBaselineRequirements] = useExportBaselineRequirementsMutation();

	const iconProps = useMemo((): IIconProps => {
		return {iconName: 'Download'};
	}, []);

	const exportBaselineRequirementsAndDownloadFile =
		useCallback(async (): Promise<void> => {
			const downloadFile = (url: string) => {
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', '');
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			};

			type ExportResult = FetchResult<ExportBaselineRequirementsMutation>;

			const selectedRequirementIds = selectedRequirements.map(
				requirement => requirement.id,
			);

			const createRequirementIdsAndExportRequirements =
				(): Promise<ExportResult> => {
					return exportBaselineRequirements({
						variables: {
							input: {baselineId, onlyRequirementsIds: selectedRequirementIds},
						},
					});
				};

			const response: ExportResult =
				await createRequirementIdsAndExportRequirements();
			const url = response.data?.exportBaselineRequirements.blobRef?.uri;
			if (url) downloadFile(url);
		}, [baselineId, selectedRequirements, exportBaselineRequirements]);

	useCommand(
		{
			key: 'download',
			text: t('download'),
			disabled: selectedRequirements.length === 0,
			iconProps,
			onClick() {
				exportBaselineRequirementsAndDownloadFile().catch(error => {
					console.error('Failed to export and download file: ', error);
				});
			},
		},
		[selectedRequirements],
	);

	return <></>;
}
