import {UseTranslationOptions, useTranslation} from 'react-i18next';
import translations from '../../../locales/features/VehicleProjects/baselinesPage.de.json';
import {
	ProjectDetailsPageParams,
	createGenPagePath,
	encodeGenAndModelSeries,
} from '../vehicleProjectPagePaths';
import {useCallback} from 'react';
import {NavigateFunction, useNavigate} from 'react-router-dom';

export const translationNamespace = 'features/VehicleProjects/baselinesPage';

type Translations = typeof translations;

export const useBaselinesPageTranslations = (
	options?: UseTranslationOptions<keyof Translations>,
) => {
	return useTranslation(translationNamespace, options);
};

export const createBaselinesPagePath = (
	fields: ProjectDetailsPageParams,
): string => {
	const genPath: string = createGenPagePath(fields);
	return `${genPath}/baselines`;
};

export type NavigateToBaselinesPage = () => void;

export const useNavigateToBaselinesPage = (
	params: ProjectDetailsPageParams,
): NavigateToBaselinesPage => {
	const navigate: NavigateFunction = useNavigate();

	return useCallback((): void => {
		const encodedParams: ProjectDetailsPageParams =
			encodeGenAndModelSeries(params);
		const path: string = createBaselinesPagePath(encodedParams);
		navigate(path);
	}, [params, navigate]);
};
