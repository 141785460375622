import {EntityList, EntityListColumn, EntityListProps} from 'components';
import React, {useCallback, useMemo} from 'react';
import {SetRequired} from 'type-fest';
import {ConstrainMode, SelectionMode} from '@fluentui/react';
import {renderDate} from 'components/EntityList/ColumnRenderers';
import {Baseline} from 'types';
import {useBaselinesPageTranslations} from './BaselinesPage.utils';
import {DownloadBtn} from './DownloadBtn';
import {
	NavigateToBaselineDetailsPage,
	useNavigateToBaselineDetailsPage,
} from '../BaselineDetailsPage/BaselineDetailsPage.utils';
import {ProjectDetailsPageParams} from '../vehicleProjectPagePaths';
import {TimeCell} from './TimeCell';
import _ from 'lodash';
import {BaselineRow} from './BaselineRow';
import {entityListStylesWithAutoOverflowX} from '../entityListStyles';

interface Props {
	baselines: Array<any>;
	params: ProjectDetailsPageParams;
}

export function BaselinesList({baselines, params}: Props): JSX.Element {
	const {t: getColumnTranslation} = useBaselinesPageTranslations({
		keyPrefix: 'Columns',
	});

	const navigateToBaselineDetailsPage: NavigateToBaselineDetailsPage =
		useNavigateToBaselineDetailsPage(params);

	const columns = useMemo((): EntityListColumn[] => {
		type FieldsToCreateColumn = SetRequired<
			Partial<EntityListColumn>,
			'key' | 'minWidth'
		>;

		/**
		 * * Assumes that the key is the same as the translation keys.
		 */
		const createCustomColumnFromKeyAndAddDefaults = ({
			key,
			...other
		}: FieldsToCreateColumn): EntityListColumn => {
			return {
				key,
				name: getColumnTranslation(key),
				tooltipHostProps: {translationKey: key},
				...other,
			};
		};

		const renderDownloadBtn = (baseline: Baseline): JSX.Element => {
			return <DownloadBtn baseline={baseline} />;
		};

		const renderTime = ({createdAt}: Baseline): JSX.Element | null => {
			return <TimeCell createdAt={createdAt} />;
		};

		const getFieldsToCreateCustomColumns = (): FieldsToCreateColumn[] => {
			const dateFieldName = 'createdAt';
			return [
				{
					key: 'Date',
					/**
					 * We provide this field because the renderer requires it.
					 */
					fieldName: dateFieldName,
					onRender: renderDate(),
					minWidth: 70,
				},
				{
					key: 'Time',
					onRender: renderTime,
					minWidth: 50,
				},
				{key: 'Download', onRender: renderDownloadBtn, minWidth: 130},
			];
		};

		const getCustomColumns = (): EntityListColumn[] => {
			const columns: FieldsToCreateColumn[] = getFieldsToCreateCustomColumns();
			return columns.map(createCustomColumnFromKeyAndAddDefaults);
		};

		const createNameColumn = (): EntityListColumn => {
			return {
				key: 'name',
				name: getColumnTranslation('Name'),
				fieldName: 'name',
				minWidth: 200,
				isResizable: true,
				isMultiline: true,
			};
		};

		const nameColumn: EntityListColumn = createNameColumn();
		const customColumns: EntityListColumn[] = getCustomColumns();
		return [nameColumn, ...customColumns];
	}, [getColumnTranslation]);

	const navigateToDetailsPageFromBaseline = useCallback(
		(baseline: Baseline): void => {
			navigateToBaselineDetailsPage(baseline.id);
		},
		[navigateToBaselineDetailsPage],
	);

	const sortedBaselines: Baseline[] = useMemo(() => {
		const compareDatesOfBaselines = (
			{createdAt: dateOfBaseline1}: Baseline,
			{createdAt: dateOfBaseline2}: Baseline,
		): number => {
			if (dateOfBaseline1 && dateOfBaseline2) {
				return dateOfBaseline1 >= dateOfBaseline2 ? -1 : 1;
			}

			/**
			 * This ensures that baselines without dates always show up last.
			 */
			return dateOfBaseline1 ? -1 : 1;
		};

		/**
		 * See the comparison function if you need more details about how they are
		 * sorted.
		 */
		const sortByDate = (baselines: Baseline[]): Baseline[] => {
			const baselinesCopy: Baseline[] = _.clone(baselines);
			return baselinesCopy.sort(compareDatesOfBaselines);
		};

		if (baselines) return sortByDate(baselines);
		/* We show an empty array just in case the server has an error. */
		return [];
	}, [baselines]);

	const renderRow: EntityListProps['onRenderRow'] = (props, defaultRender) => {
		return <BaselineRow defaultProps={props} defaultRender={defaultRender} />;
	};

	return (
		<EntityList
			columns={columns}
			items={sortedBaselines}
			constrainMode={ConstrainMode.unconstrained}
			styles={entityListStylesWithAutoOverflowX}
			selectionMode={SelectionMode.none}
			onItemInvoked={navigateToDetailsPageFromBaseline}
			onRenderRow={renderRow}
			sticky
		/>
	);
}
