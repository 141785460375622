import {
	Label,
	Link,
	mergeStyleSets,
	SearchBox,
	Separator,
	Spinner,
	SpinnerSize,
	Theme,
	useTheme,
} from '@fluentui/react';
import {useOutsideClick} from 'hooks';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {FullTextSearchResult, RegulatoryDocumentParagraph} from 'types';
import {useQuickSearchLazyQuery} from './hooks/useQuickSearch.generated';
import {getParagraphLabel} from 'features/RegulatoryDocuments/utils';

export const TextSearchBar: React.FC = () => {
	const {t} = useTranslation('components/textsearchbar');
	const theme = useTheme();
	const navigate = useNavigate();
	const classNames = getClassNames(theme);

	const [getSearchResults, {loading}] = useQuickSearchLazyQuery();

	const [searchText, setSearchText] = React.useState('');
	const [hideSuggestions, setHideSuggestions] = React.useState(false);
	const [suggestions, setSuggestions] = React.useState<
		FullTextSearchResult | undefined
	>(undefined);

	const onOutsideClick = React.useCallback(() => {
		setHideSuggestions(true);
	}, []);
	const ref = useOutsideClick(onOutsideClick);

	const onFocus = React.useCallback(() => {
		setHideSuggestions(false);
	}, [searchText, suggestions]);

	const onNavigate = React.useCallback(() => setHideSuggestions(true), []);

	let typingTimerId: NodeJS.Timeout;

	React.useEffect(() => {
		let typingTimerId: NodeJS.Timeout;

		if (searchText.length > 2) {
			typingTimerId = setTimeout(() => {
				getSearchResults({
					variables: {searchString: searchText, limit: 6},
				}).then(res => setSuggestions(res.data?.fullTextSearch as any));
				setHideSuggestions(false);
			}, 1000);
		} else {
			setSuggestions(undefined);
		}

		return () => typingTimerId && clearTimeout(typingTimerId);
	}, [searchText]);

	const onSearch = React.useCallback(
		(text: string) => {
			if (text.length > 2) {
				setHideSuggestions(true);
				if (typingTimerId) {
					clearTimeout(typingTimerId);
				}

				navigate(`/search/?text=${text}`);
			}
		},
		[searchText, navigate],
	);

	return (
		<div className={classNames.componentWrapper} ref={ref}>
			<SearchBox
				placeholder={t('SearchPlaceholder')}
				onChange={(_, val) => val && setSearchText(val)}
				onClear={() => setSearchText('')}
				onFocus={onFocus}
				onSearch={onSearch}
				styles={{
					root: {
						position: 'relative',
					},
				}}
			/>
			{loading && (
				<div className={classNames.suggestions}>
					<Spinner size={SpinnerSize.large} />
				</div>
			)}
			{suggestions && !hideSuggestions && !loading && (
				<div className={classNames.suggestions}>
					<ResultItem
						label='Regulations'
						items={suggestions.regulations}
						getUrl={reg => `/regulations/${reg.id}`}
						renderItem={reg => <>{`${reg.regulationNumber} ${reg.name}`}</>}
						separator
						onNavigate={onNavigate}
						viewResultsUrl={`search?text=${searchText}&entity=regulations`}
					/>
					<ResultItem
						label='Regulatory Documents'
						items={suggestions.regulatoryDocuments}
						getUrl={regDoc =>
							regDoc.regulation
								? `/regulations/${regDoc.regulation.id}/${regDoc.id}/paragraphs`
								: `/regulatoryDocuments/${regDoc.id}`
						}
						renderItem={regDoc => (
							<>
								{regDoc.regulation
									? `${regDoc.regulation.regulationNumber} ${regDoc.regulation.name} > ${regDoc.name}`
									: regDoc.name}
							</>
						)}
						separator
						onNavigate={onNavigate}
						viewResultsUrl={`search?text=${searchText}&entity=regulatoryDocuments`}
					/>
					<ResultItem
						label='Paragraphs'
						items={suggestions.paragraphs}
						getUrl={par =>
							`/regulations/${par.parent.regulation?.id}/${par.parent.id}/paragraphs/${par.id}`
						}
						renderItem={ParagraphItem}
						onNavigate={onNavigate}
						viewResultsUrl={`search?text=${searchText}&entity=paragraphs`}
					/>
				</div>
			)}
		</div>
	);
};

const ParagraphItem = (par: RegulatoryDocumentParagraph) => {
	const paragraphLabel = getParagraphLabel(par);

	return (
		<React.Fragment>
			{par.parent.regulation
				? `${par.parent.regulation.regulationNumber} ${par.parent.regulation.name} > ${par.parent.name} > ${paragraphLabel}`
				: `${par.parent.name} > ${paragraphLabel}`}
		</React.Fragment>
	);
};

type ResultItemProps<T> = {
	label: string;
	items: T[];
	renderItem: (item: T) => JSX.Element;
	getUrl: (item: T) => string;
	viewResultsUrl: string;
	separator?: boolean;
	onNavigate?: () => void;
};

function ResultItem<T>(props: ResultItemProps<T>) {
	const {
		label,
		items,
		renderItem,
		getUrl,
		viewResultsUrl,
		separator = false,
		onNavigate,
	} = props;

	const {t} = useTranslation('components/textsearchbar');
	const theme = useTheme();
	const {resultItem, moreButtonWrapper} = getClassNames(theme);

	const navigate = useNavigate();
	const getOnResultClick = React.useCallback(
		(url: string) => () => {
			onNavigate?.();
			navigate(url);
		},
		[navigate],
	);
	return (
		<>
			<Label>{label}</Label>
			{items.map((item, i) => (
				<div
					key={i}
					onClick={getOnResultClick(getUrl(item))}
					className={resultItem}
				>
					{renderItem(item)}
				</div>
			))}
			<div className={moreButtonWrapper}>
				{items.length > 0 && (
					<Link
						onClick={() => {
							onNavigate?.();
							navigate(viewResultsUrl);
						}}
					>
						{t('MoreResults')}
					</Link>
				)}
			</div>
			{separator && <Separator />}
		</>
	);
}

const getClassNames = (theme: Theme) =>
	mergeStyleSets({
		componentWrapper: {
			width: 600,
			zIndex: 10,
			paddingRight: '50px',
			position: 'relative',
			top: '50%',
			'-webkit-transform': 'translateY(-50%)',
			'-ms-transform': 'translateY(-50%)',
			transform: 'translateY(-50%)',
		},
		suggestions: {
			background: theme.palette.white,
			position: 'absolute',
			zIndex: 10,
			width: 'calc(600px - 32px)',
			top: '35px',
			padding: '16px',
			boxShadow: `0px 3px 10px ${theme.palette.neutralPrimary}`,
			maxHeight: 'calc(100vh - 100px)',
			overflowY: 'scroll',
		},
		resultItem: {
			padding: '4px 0',
			cursor: 'pointer',
			':hover': {
				background: `${theme.palette.neutralLight}cc`,
			},
		},
		moreButtonWrapper: {
			textAlign: 'right',
		},
	});
