import {Regulation, RegulatoryDocument} from 'types';
import i18n from 'i18n/i18n';
import {TFunction} from 'react-i18next';
import {formatDateTime} from 'i18n/localeDateFormat';
import {
	IHTMLXL,
	renderHeaderXL,
	renderHTMLExcel,
	cleanHTMLExcel,
	createZIPXL,
} from './ExcelExportCommon';
import Excel, {RichText, Table, Worksheet} from 'exceljs';
import {DocumentSourceEnum} from 'features/Regulations/RegulationDetail/RegulationDetail.types';

interface IRegulationDetailPage {
	(entityListItems: any[], pageDetails: any, t: TFunction): Promise<void>;
}

interface IRenderTable {
	(
		arrRegDocs: RegulatoryDocument[],
		sheet: Worksheet,
		table: Table,
		intRowCountTop: number,
		t: TFunction,
	): number;
}

export const handleClickRegulationDetailPage: IRegulationDetailPage = async (
	entityListItems,
	pageDetails,
	t,
) => {
	const arrTables: string[] = [];
	const arrImagesUri: string[] = [];
	const arrImagesName: string[] = [];

	let arrRegDocFilt = entityListItems as RegulatoryDocument[];
	const regulation = pageDetails as Regulation;
	const arrRegDoc = regulation.regulatoryDocuments.filter(
		x =>
			x.documentSource?.name?.toLowerCase() !==
			DocumentSourceEnum.Pogis.toLowerCase(),
	);
	const arrRegDocPOGIS = regulation.regulatoryDocuments.filter(
		x =>
			x.documentSource?.name?.toLowerCase() ===
			DocumentSourceEnum.Pogis.toLowerCase(),
	);

	const workbook = new Excel.Workbook();
	const arrColWidth: number[] = [
		50, 100, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20,
	];
	const strPageName = t('Documents', {
		ns: 'features/regulations',
		keyPrefix: 'RegulationDetail',
	});
	const sheet = workbook.addWorksheet('TRACE_' + strPageName);
	workbook.properties.date1904 = true;
	workbook.calcProperties.fullCalcOnLoad = false;

	renderHeaderXL(workbook, sheet, arrColWidth, strPageName, t);

	let intRowCount = 4;
	let row = sheet.getRow(intRowCount);
	row.alignment = {wrapText: true, vertical: 'top'};
	row.getCell('B').value = {
		richText: [
			{
				font: {bold: true, name: 'Porsche Next TT', size: 10},
				text:
					t('RegulationNumber', {
						ns: 'features/regulations',
						keyPrefix: 'RegulationsList',
					}) + '\n',
			},
			{
				font: {bold: false, name: 'Porsche Next TT', size: 10},
				text: regulation.regulationNumber ?? '',
			},
		],
	};

	row.getCell('C').value = {
		richText: [
			{
				font: {bold: true, name: 'Porsche Next TT', size: 10},
				text:
					t('Name', {
						ns: 'features/regulations',
						keyPrefix: 'RegulationsList',
					}) + '\n',
			},
			{
				font: {bold: false, name: 'Porsche Next TT', size: 10},
				text: regulation.name ?? '',
			},
		],
	};

	intRowCount++;
	row = sheet.getRow(intRowCount);
	row.alignment = {wrapText: true, vertical: 'top'};

	row.getCell('B').value = {
		richText: [
			{
				font: {bold: true, name: 'Porsche Next TT', size: 10},
				text:
					t('ContactPersonLabel', {
						ns: 'features/regulations',
						keyPrefix: 'RegulationDetail',
					}) + '\n',
			},
			{
				font: {bold: false, name: 'Porsche Next TT', size: 10},
				text: regulation.contactPersons.map(a => a.name).join(';\n'),
			},
		],
	};

	const tempDivElement = document.createElement('div');
	const arrHTMLXL: IHTMLXL[] = [];
	const arrHTMLXLClean: IHTMLXL[] = [
		{
			font: {bold: true, name: 'Porsche Next TT', size: 10},
			text:
				t('ShortSummaryLabel', {
					ns: 'features/regulations',
					keyPrefix: 'RegulationDetail',
				}) + '\n',
		},
	];
	tempDivElement.innerHTML = regulation.summary
		? '<div>' + regulation.summary + '</div>'
		: '<div></div>';
	renderHTMLExcel(tempDivElement.childNodes[0].childNodes, arrHTMLXL);
	cleanHTMLExcel(arrHTMLXL, arrHTMLXLClean);
	row.getCell('C').value = {richText: arrHTMLXLClean as RichText[]};

	intRowCount++;
	row = sheet.getRow(intRowCount);
	row.alignment = {wrapText: true, vertical: 'top'};

	row.getCell('B').value = {
		richText: [
			{
				font: {bold: true, name: 'Porsche Next TT', size: 10},
				text:
					t('MainKeywordsLabel', {
						ns: 'features/regulations',
						keyPrefix: 'RegulationDetail',
					}) + '\n',
			},
			{
				font: {bold: false, name: 'Porsche Next TT', size: 10},
				text: regulation.mainKeywords.map(a => a.name).join('; '),
			},
		],
	};

	row.getCell('C').value = {
		richText: [
			{
				font: {bold: true, name: 'Porsche Next TT', size: 10},
				text:
					t('KeyWordsLabel', {
						ns: 'features/regulations',
						keyPrefix: 'RegulationDetail',
					}) + '\n',
			},
			{
				font: {bold: false, name: 'Porsche Next TT', size: 10},
				text: regulation.keywords.map(a => a.name).join('; '),
			},
		],
	};

	row.getCell('D').value = {
		richText: [
			{
				font: {bold: true, name: 'Porsche Next TT', size: 10},
				text:
					t('MarketsLabel', {
						ns: 'features/regulations',
						keyPrefix: 'RegulationDetail',
					}) + '\n',
			},
			{
				font: {bold: false, name: 'Porsche Next TT', size: 10},
				text: regulation.markets.map(a => a.name).join('; '),
			},
		],
	};

	intRowCount++;
	row = sheet.getRow(intRowCount);
	row.alignment = {wrapText: true, vertical: 'top'};

	if (regulation.attachments && regulation.attachments.length > 0) {
		intRowCount++;
		row = sheet.getRow(intRowCount);
		row.getCell('B').alignment = {wrapText: true, vertical: 'top'};
		row.getCell('B').value = {
			richText: [
				{
					font: {bold: true, name: 'Porsche Next TT', size: 10},
					text:
						t('AttachmentsLabel', {
							ns: 'features/regulations',
							keyPrefix: 'RegulationDetail',
						}) + '\n',
				},
				{
					font: {bold: false, name: 'Porsche Next TT', size: 10},
					text: regulation.attachments
						?.map(attach => attach.attachmentId)
						.join('; '),
				},
			],
		};

		intRowCount++;
		row = sheet.getRow(intRowCount);
		row.getCell('B').style.font = {
			underline: true,
			bold: false,
			color: {argb: '00B0F0'},
		};
		row.getCell('B').value = {
			text: 'link to attachments',
			hyperlink: 'attachments',
		};
	}

	intRowCount++;
	const columnsXL = [
		{
			name: t('Version', {
				ns: 'features/regulatorydocuments',
				keyPrefix: 'RegulatoryDocumentsList',
			}),
			filterButton: false,
		},
		{
			name: t('Keywords', {
				ns: 'features/regulatorydocuments',
				keyPrefix: 'RegulatoryDocumentsList',
			}),
			filterButton: false,
		},
		{
			name: t('Status', {
				ns: 'features/regulatorydocuments',
				keyPrefix: 'RegulatoryDocumentsList',
			}),
			filterButton: true,
		},
		{
			name: t('WorkflowStatus', {
				ns: 'features/regulatorydocuments',
				keyPrefix: 'RegulatoryDocumentsList',
			}),
			filterButton: true,
		},
		{
			name: t('ModifiedAt', {
				ns: 'features/regulatorydocuments',
				keyPrefix: 'RegulatoryDocumentsList',
			}),
			filterButton: true,
		},
		{
			name: t('DateNewTypes', {
				ns: 'features/regulatorydocuments',
				keyPrefix: 'RegulatoryDocumentsList',
			}),
			filterButton: true,
		},
		{
			name: t('DateNewRegistration', {
				ns: 'features/regulatorydocuments',
				keyPrefix: 'RegulatoryDocumentsList',
			}),
			filterButton: true,
		},
		{
			name: t('DateEffective', {
				ns: 'features/regulatorydocuments',
				keyPrefix: 'RegulatoryDocumentsList',
			}),
			filterButton: true,
		},
		{
			name: t('DateExpiration', {
				ns: 'features/regulatorydocuments',
				keyPrefix: 'RegulatoryDocumentsList',
			}),
			filterButton: true,
		},
		{
			name: t('ModelYear', {
				ns: 'features/regulatorydocuments',
				keyPrefix: 'RegulatoryDocumentsList',
			}),
			filterButton: true,
		},
		{
			name: t('PhaseIn', {
				ns: 'features/regulatorydocuments',
				keyPrefix: 'RegulatoryDocumentsList',
			}),
			filterButton: false,
		},
		{
			name: t('PhaseOut', {
				ns: 'features/regulatorydocuments',
				keyPrefix: 'RegulatoryDocumentsList',
			}),
			filterButton: false,
		},
		{
			name: t('Liability', {
				ns: 'features/regulatorydocuments',
				keyPrefix: 'RegulatoryDocumentsList',
			}),
			filterButton: true,
		},
	];

	row = sheet.getRow(intRowCount);
	row.font = {
		bold: true,
		name: 'Porsche Next TT',
		size: 10,
		color: {argb: 'FFFFFFFF'},
	};

	sheet.addTable({
		name: 'tableRegulatoryDocuments',
		ref: 'B' + String(intRowCount),
		style: {
			showRowStripes: true,
		},
		columns: columnsXL,
		rows: [],
	});

	let table = sheet.getTable('tableRegulatoryDocuments');

	if (arrRegDocFilt.length === 0 && arrRegDoc.length > 0) {
		arrRegDocFilt = arrRegDoc;
	} else {
		table.addRow(['', '', '', '', '', '', '', '', '', '', '', '', '']);
	}

	intRowCount++;
	intRowCount = renderTable(arrRegDocFilt, sheet, table, intRowCount, t);
	table.commit();

	if (arrRegDocPOGIS.length > 0) {
		intRowCount++;
		intRowCount++;
		row = sheet.getRow(intRowCount);
		row.getCell('B').value = {
			richText: [
				{
					font: {bold: true, name: 'Porsche Next TT', size: 10},
					text:
						t('PogisEntry', {
							ns: 'features/regulations',
							keyPrefix: 'RegulationDetail',
						}) + '\n',
				},
			],
		};

		intRowCount++;
		sheet.addTable({
			name: 'tableRegulatoryDocumentsPOGIS',
			ref: 'B' + String(intRowCount),
			style: {
				showRowStripes: true,
			},
			columns: columnsXL,
			rows: [],
		});

		table = sheet.getTable('tableRegulatoryDocumentsPOGIS');
		intRowCount++;
		intRowCount = renderTable(arrRegDocPOGIS, sheet, table, intRowCount, t);
		table.commit();
	}

	const strZipName =
		strPageName + '_' + atob(regulation.id).split('\n')[1].slice(1);
	await createZIPXL(
		workbook,
		strZipName,
		t,
		arrTables,
		arrImagesUri,
		arrImagesName,
		regulation.attachments!,
	);
};

const renderTable: IRenderTable = (
	arrRegDoc,
	sheet,
	table,
	intRowCountTop,
	t,
) => {
	let intRowCount = intRowCountTop;
	let row = sheet.getRow(intRowCount);
	for (let ii = 0; ii < arrRegDoc.length; ii++) {
		row = sheet.getRow(intRowCount);
		row.alignment = {wrapText: true, vertical: 'top'};
		table.addRow([
			arrRegDoc[ii].name,
			arrRegDoc[ii].keywords.map(keyword => keyword.name).join(';\n'),
			arrRegDoc[ii].status
				? t(arrRegDoc[ii].status, {
						ns: 'common/enums',
						keyPrefix: 'RegulatoryDocumentStatus',
				  })
				: '',
			arrRegDoc[ii].workflow.status && arrRegDoc[ii].workflow.status
				? t(arrRegDoc[ii].workflow.status, {
						ns: 'common/enums',
						keyPrefix: 'WorkflowStatus',
				  })
				: '',
			arrRegDoc[ii].modifiedAt
				? formatDateTime(new Date(arrRegDoc[ii].modifiedAt), i18n)
				: '',
			arrRegDoc[ii].dateNewTypes
				? formatDateTime(new Date(arrRegDoc[ii].dateNewTypes), i18n)
				: '',
			arrRegDoc[ii].dateNewRegistration
				? formatDateTime(new Date(arrRegDoc[ii].dateNewRegistration), i18n)
				: '',
			arrRegDoc[ii].dateEffective
				? formatDateTime(new Date(arrRegDoc[ii].dateEffective), i18n)
				: '',
			arrRegDoc[ii].dateExpiration
				? formatDateTime(new Date(arrRegDoc[ii].dateExpiration), i18n)
				: '',
			arrRegDoc[ii].modelYear ?? '',
			(arrRegDoc[ii].phaseIn ?? [])
				.map(
					PI =>
						(PI.date ? formatDateTime(new Date(PI.date), i18n) : '') +
						' (' +
						(PI.status ?? '') +
						')',
				)
				.join(';\n'),
			(arrRegDoc[ii].phaseOut ?? [])
				.map(
					PI =>
						(PI.date ? formatDateTime(new Date(PI.date), i18n) : '') +
						' (' +
						(PI.status ?? '') +
						')',
				)
				.join(';\n'),
			'',
		]);
		intRowCount++;
	}

	return intRowCount;
};
