import {diffWords} from 'diff';
import {ParagraphElementType} from 'types';
import {RegDoc} from '../CompareVersionPanel/ComparisonComponent/ComparisonComponent.types';

type QueryParagraph = RegDoc['paragraphs'][number];

type QueryElement = QueryParagraph['elements'][number];

interface Element extends QueryElement {
	// This type is more specific than that of the query
	asset?: {
		uri: string;
	} | null;
}

export interface IParagraphCompareInput
	extends Pick<QueryParagraph, 'elements'> {
	elements: Element[];
	enumeration: string;
	level: number;
	page: number;
}

export interface ComparableAsset {
	assetId: string;
	uri: string;
	/**
	 * The paragraph text elements' text will be aggregated into a single
	 * string. This is the character index that the asset should be placed after.
	 */
	index: number;
}

export interface IComparable extends Pick<IParagraphCompareInput, 'elements'> {
	enumeration: string;
	text: string;
	assets: Array<ComparableAsset>;
	[key: string]: any;
}

export enum CompareResult {
	Equal,
	New,
	Deleted,
	Changed,
	InReview,
}

export interface IComparedParagraph {
	paragraph: IComparable;
	textElements: ITextCompareElement[];
}

export interface IComparedItem {
	oldParagraph?: IComparedParagraph;
	newParagraph?: IComparedParagraph;
	resultType: CompareResult;
}

export interface ITextCompareElement {
	text: string;
	/**
	 * Whether or not the text exists in the current paragraph while not existing
	 * in the other paragraph.
	 */
	isChange: boolean;
}

export interface ITextCompareResult {
	textElementsOld: ITextCompareElement[];
	textElementsNew: ITextCompareElement[];
}

export function cmpText(
	textOld: string,
	textNew: string,
): ITextCompareResult | undefined {
	/**
	 * Unlike the function's name implies, the results include words that were
	 * changed and words that were not changed. You must use each array item's
	 * props to determine if a change occurred.
	 */
	const diffRes = diffWords(textOld, textNew);

	const hasNoChanges = diffRes.every(diff => !diff.added && !diff.removed);

	if (hasNoChanges) {
		return undefined;
	}

	/**
	 * The comparison result includes all words in the two compared strings. Here,
	 * we exclude the comparison results related to the new string to get those
	 * related to the old string.
	 */
	const changesOldText = diffRes.filter(diff => !diff.added);
	const changesNewText = diffRes.filter(diff => !diff.removed);

	return {
		textElementsOld: changesOldText.map(c => ({
			text: c.value,
			isChange: Boolean(c.removed),
		})),
		textElementsNew: changesNewText.map(c => ({
			text: c.value,
			isChange: Boolean(c.added),
		})),
	};
}

export function convertToComparables(paragraphs: IParagraphCompareInput[]) {
	const comparables: IComparable[] = paragraphs.map(p => {
		let text = '';
		const assets = [];

		for (const e of p.elements) {
			/**
			 * This might be here to prevent the text comparison algorithm from
			 * comparing images' text. It appears that image elements have a "text"
			 * property set to their file names by default.
			 */
			if (!e.isHeader && !e.assetId) {
				text += e.text ?? '';
			}

			if (e.asset && e.assetId) {
				assets.push({
					assetId: e.assetId,
					uri: e.asset.uri,
					index: text.length,
				});
			}
		}

		return {
			...p,
			enumeration: p.enumeration.trim(),
			text,
			assets,
		};
	});

	return comparables;
}

export function compareParagraphs(
	oldDocumentParagraphs: IParagraphCompareInput[],
	newDocumentParagraphs: IParagraphCompareInput[],
): IComparedItem[] {
	const oldVersion = convertToComparables(oldDocumentParagraphs);
	const newVersion = convertToComparables(newDocumentParagraphs);

	return compareVersions(oldVersion, newVersion);
}

const getTableOrImageElementTypes = (): ParagraphElementType[] => {
	return [
		ParagraphElementType.HtmlTable,
		ParagraphElementType.Image,
		ParagraphElementType.Table,
	];
};

const getIfIsTableOrImageElement = (element: Element): boolean => {
	const tableOrImageTypes: ParagraphElementType[] =
		getTableOrImageElementTypes();
	return tableOrImageTypes.includes(element.type);
};

const getIfElementHasTableOrImage = (element: Element): boolean => {
	const isTableOrImgElement: boolean = getIfIsTableOrImageElement(element);
	/**
	 * We check if it has an asset because legacy paragraphs can have text
	 * elements that have assets, which means they are displayed as images
	 * instead. Whether or not the asset's URI is empty does not matter because
	 * EditorContent will render it as an image anyways. So, we must consider it
	 * an image here, too.
	 */
	return isTableOrImgElement || Boolean(element.asset);
};

type PossibleElement = Element | undefined;

const findTableOrImageElement = (elements: Element[]): PossibleElement => {
	return elements.find(getIfElementHasTableOrImage);
};

const getIfComparableHasTableOrImg = ({elements}: IComparable): boolean => {
	const elementForReview: PossibleElement = findTableOrImageElement(elements);
	return Boolean(elementForReview);
};

const getIfComparablesBothHaveTablesOrImages = (
	comparables: IComparable[],
): boolean => {
	return comparables.every(getIfComparableHasTableOrImg);
};

const getStatusForInReviewOrDefaultStatus = (
	areBothParagraphsInReview: boolean,
	defaultResultType: CompareResult,
): CompareResult => {
	return areBothParagraphsInReview ? CompareResult.InReview : defaultResultType;
};

/**
 * Notes:
 *
 * - idx = index
 */
export function compareVersions(
	oldVersion: IComparable[],
	newVersion: IComparable[],
): IComparedItem[] {
	let idxOld = 0;
	let idxNew = 0;

	const result: IComparedItem[] = [];

	// While there are items in newVersion and oldVersion
	while (idxNew < newVersion?.length && idxOld < oldVersion?.length) {
		const currParagraphOld = oldVersion[idxOld];
		const currParagraphNew = newVersion[idxNew];

		if (currParagraphOld.enumeration === currParagraphNew.enumeration) {
			const textCmpResult = cmpText(
				currParagraphOld.text,
				currParagraphNew.text,
			);

			const comparables: IComparable[] = [currParagraphOld, currParagraphNew];
			const areBothParagraphsInReview: boolean =
				getIfComparablesBothHaveTablesOrImages(comparables);

			// If the comparison result exists, it means there were changes
			if (textCmpResult) {
				const {textElementsOld} = textCmpResult;

				result.push({
					oldParagraph: {
						paragraph: currParagraphOld,
						textElements: textElementsOld,
					},
					newParagraph: {
						paragraph: currParagraphNew,
						textElements: textCmpResult.textElementsNew,
					},
					resultType: getStatusForInReviewOrDefaultStatus(
						areBothParagraphsInReview,
						CompareResult.Changed,
					),
				});
			} else {
				result.push({
					oldParagraph: {
						paragraph: currParagraphOld,
						textElements: [{text: currParagraphOld.text, isChange: false}],
					},
					newParagraph: {
						paragraph: currParagraphNew,
						textElements: [{text: currParagraphNew.text, isChange: false}],
					},
					resultType: getStatusForInReviewOrDefaultStatus(
						areBothParagraphsInReview,
						CompareResult.Equal,
					),
				});
			}

			idxOld++;
			idxNew++;
		} else {
			let matchFound = false;

			/**
			 * We iterate over the new and old version's paragraphs. We only iterate
			 * over the ones that are after the two paragraphs we got in the while
			 * loop.
			 */
			for (
				let indexAfterOldParagraph = idxOld + 1,
					indexAfterNewParagraph = idxNew + 1;
				indexAfterOldParagraph < oldVersion.length ||
				indexAfterNewParagraph < newVersion.length;
				indexAfterOldParagraph++, indexAfterNewParagraph++
			) {
				const paragraphAfterOld = oldVersion[indexAfterOldParagraph];
				const paragraphAfterNew = newVersion[indexAfterNewParagraph];

				/**
				 * If we found the new version's paragraph in the old version's paragraphs
				 */
				if (
					indexAfterOldParagraph < oldVersion.length &&
					paragraphAfterOld.enumeration === currParagraphNew.enumeration
				) {
					/**
					 * By definition, deleted paragraphs are those that exist only on the
					 * old version. Since the paragraphs in between the old paragraph and
					 * the found paragraph exist only on the old version, we mark them as
					 * deleted.
					 */
					oldVersion
						.slice(idxOld, indexAfterOldParagraph)
						.forEach(oldParagraph => {
							result.push({
								oldParagraph: {
									paragraph: oldParagraph,
									textElements: [{text: oldParagraph.text, isChange: false}],
								},
								resultType: CompareResult.Deleted,
							});
						});
					idxOld = indexAfterOldParagraph;
					matchFound = true;
					break;
				}

				/**
				 * If we found the old paragraph in the new version's paragraphs
				 */
				if (
					indexAfterNewParagraph < newVersion.length &&
					paragraphAfterNew.enumeration === currParagraphOld.enumeration
				) {
					/**
					 * Here, we do the opposite of what we do for the old version. See
					 * above for more info.
					 */
					newVersion
						.slice(idxNew, indexAfterNewParagraph)
						.forEach(newParagraph => {
							result.push({
								newParagraph: {
									paragraph: newParagraph,
									textElements: [{text: newParagraph.text, isChange: false}],
								},
								resultType: CompareResult.New,
							});
						});
					idxNew = indexAfterNewParagraph;
					matchFound = true;
					break;
				}
			}

			if (!matchFound) {
				result.push({
					resultType: CompareResult.New,
					newParagraph: {
						paragraph: currParagraphNew,
						textElements: [{text: currParagraphNew.text, isChange: false}],
					},
				});
				result.push({
					resultType: CompareResult.Deleted,
					oldParagraph: {
						paragraph: currParagraphOld,
						textElements: [{text: currParagraphOld.text, isChange: false}],
					},
				});
				idxOld++;
				idxNew++;
			}

			/*
               	Hold the current paragraph from the new version and start looking for a 
                paragraph in the old version that has the same enumeration.
                If one is found, then the paragraphs in between in the old version were deleted
            
			for (let i = idxOld + 1; i < oldVersion.length; i++) {
				const currLookupOld = oldVersion[i];

				if (currLookupOld.enumeration === currParagraphNew.enumeration) {
					oldVersion.slice(idxOld, i).map(oldParagraph => {
						result.push({
							oldParagraph,
							resultType: CompareResult.Deleted,
						});
					});
					idxOld = i;
					continue;
				}
			}

			
                Hold the current paragraph from the old version and start looking for a 
                paragraph in the new version that has the same enumeration.
                If one is found, then the paragraphs in between in the new version were added
            
			for (let i = idxNew + 1; i < newVersion.length; i++) {
				const currLookupNew = newVersion[i];

				if (currLookupNew.enumeration === currParagraphOld.enumeration) {
					newVersion.slice(idxNew, i).map(newParagraph => {
						result.push({
							newParagraph,
							resultType: CompareResult.New,
						});
					});
					idxNew = i;
					continue;
				}
			}
			*/
		}
	}

	// We are at the end of the new Version, so all paragraphs that are left in the old one are deleted
	if (idxNew === newVersion?.length) {
		oldVersion.slice(idxOld, oldVersion.length).forEach(oldParagraph => {
			result.push({
				resultType: CompareResult.Deleted,
				oldParagraph: {
					paragraph: oldParagraph,
					textElements: [{text: oldParagraph.text, isChange: false}],
				},
			});
		});

		return result;
	}

	// We are at the end of the old Version, so all paragraphs that are left in the new one are added
	if (idxOld === oldVersion?.length) {
		newVersion.slice(idxNew, newVersion.length).forEach(newParagraph => {
			result.push({
				newParagraph: {
					paragraph: newParagraph,
					textElements: [{text: newParagraph.text, isChange: false}],
				},
				resultType: CompareResult.New,
			});
		});

		return result;
	}

	return [];
}
