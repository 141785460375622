import {Dispatch, SetStateAction, useState} from 'react';
import {
	StateForEntityFormPanels,
	useStateForEntityFormPanels,
} from 'components/EntityPage/hooksForEntityFormPanels';
import {
	RequirementsFormElementsInfo,
	UpdateRequirement,
	useRequirementUpdater,
	useRequirementsFormElementsRenderer,
} from '../hooks/requirementFormHooks';
import {
	RequirementDetailsResult,
	RequirementFromDetailsPage,
} from './requirementDetailsPage.types';
import {QueryResult, RefetchQueriesInclude} from '@apollo/client';
import {
	RequirementDetailsDocument,
	RequirementDetailsQuery,
} from '../hooks/requirementDetails.generated';
import {useEditEntityCmd} from 'hooks/useEditEntityCmd';
import {FormMode} from 'components/EntityPage/EntityPage.types';
import {RequirementStatus} from 'types';
import {useUserContext} from 'authentication/UserContext';

type StateForPanels = StateForEntityFormPanels<RequirementFromDetailsPage>;

type SetIsFormSubmittingOrRefetching = Dispatch<SetStateAction<boolean>>;

export interface RequirementDetailsPageFormInfo {
	commonStateForPanels: StateForPanels;
	formDataLoading: QueryResult['loading'];
	renderFormElements: RequirementsFormElementsInfo<RequirementFromDetailsPage>['renderFormElements'];
	updateRequirement: UpdateRequirement;
	refetchQueries: RefetchQueriesInclude;
	setIsFormSubmittingOrRefetching: SetIsFormSubmittingOrRefetching;
}

type PossibleRequirement = RequirementDetailsQuery['requirement'];

type RequirementData = RequirementDetailsResult['data'];

interface EditCmdFields {
	requirementData: RequirementData;
	isFormSubmittingOrRefetching: boolean;
	commonStateForPanels: StateForPanels;
	hasEditableChildren?: boolean;
}

const useEditCmd = ({
	requirementData,
	isFormSubmittingOrRefetching,
	commonStateForPanels: {
		formInfo: {reset},
		modeInfo: [_mode, setMode],
	},
	hasEditableChildren,
}: EditCmdFields): void => {
	const requirement: PossibleRequirement = requirementData?.requirement;
	const {isAdmin, myVexClusters, isReader} = useUserContext();
	const vexClusterMatch = requirement?.vexClusters.some(vc =>
		myVexClusters.some(mvc => mvc.id === vc.id),
	);
	useEditEntityCmd(
		{
			key: 'edit',
			/**
			 * We prevent users from opening the form when the requirement does not
			 * exist because the form requires the requirement data.
			 *
			 * To prevent the user from opening the form with the old
			 * requirement data, we must disable this button if the form is
			 * submitting or refetching the requirement data. Note that we do not use
			 * the form state's "isSubmitting" property because it does not update
			 * when there is an error.
			 */
			disabled:
				hasEditableChildren === true ||
				!requirement ||
				isFormSubmittingOrRefetching ||
				requirement?.status === RequirementStatus.Approval ||
				(!isAdmin && !vexClusterMatch),
			hidden: isReader,
			onClick() {
				// To set the default values
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				reset(requirement!);
				// eslint-disable-next-line no-unused-expressions
				(requirement as any).status === 'FINAL'
					? setMode(FormMode.Create)
					: setMode(FormMode.Update);
			},
		},
		[requirement, isFormSubmittingOrRefetching],
	);
};

export const useRequirementDetailsPageEditCmdAndFormInfo = (
	requirementData: RequirementData,
	hasEditableChildren?: boolean,
): RequirementDetailsPageFormInfo => {
	const {formDataResult, renderFormElements} =
		useRequirementsFormElementsRenderer<RequirementFromDetailsPage>();

	const [isFormSubmittingOrRefetching, setIsFormSubmittingOrRefetching] =
		useState<boolean>(false);

	const commonStateForPanels: StateForPanels = useStateForEntityFormPanels();

	useEditCmd({
		requirementData,
		commonStateForPanels,
		isFormSubmittingOrRefetching,
		hasEditableChildren,
	});

	const refetchQueries: RefetchQueriesInclude = [RequirementDetailsDocument];

	return {
		formDataLoading: formDataResult.loading,
		updateRequirement: useRequirementUpdater(refetchQueries),
		refetchQueries,
		renderFormElements,
		commonStateForPanels,
		setIsFormSubmittingOrRefetching,
	};
};
