export const REPORTS_TABLE_NAME = 'reports';
export const isReportsTable = (id: string | undefined) =>
	id === REPORTS_TABLE_NAME;

export const isReportsTableIsoDate = (
	id: string | undefined,
	fieldName: string | undefined,
) =>
	isReportsTable(id) &&
	(fieldName === 'dateEffective' ||
		fieldName === 'dateNewRegistration' ||
		fieldName === 'dateNewTypes' ||
		fieldName === 'createdAt');
export const DEFAULT_DATE = '1970-01-01T00:00:00.000Z';
