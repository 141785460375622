import {FontWeights, IconButton, Modal, mergeStyleSets} from '@fluentui/react';
import React from 'react';

export const InfoModal = ({isModalOpen, hideModal}: InfoModalProps) => {
	return (
		<Modal
			titleAriaId='versionModal'
			isOpen={isModalOpen}
			onDismiss={hideModal}
			isBlocking={false}
		>
			<div className={modalStyles.header}>
				<h2 className={modalStyles.heading}>03.09.2024 | Version 1.18.7</h2>
				<IconButton
					iconProps={{iconName: 'Cancel'}}
					className={modalStyles.icon}
					ariaLabel='Close popup modal'
					onClick={hideModal}
				/>
			</div>
		</Modal>
	);
};

const modalStyles = mergeStyleSets({
	container: {
		display: 'flex',
		flexFlow: 'column nowrap',
		alignItems: 'stretch',
	},
	header: [
		{
			flex: '1 1 auto',
			display: 'flex',
			alignItems: 'center',
			fontWeight: FontWeights.semibold,
			padding: '12px 12px 14px 24px',
		},
	],
	heading: {
		fontWeight: FontWeights.semibold,
		fontSize: 'inherit',
		margin: '0',
	},
	icon: {
		marginLeft: 'auto',
	},
});

export type InfoModalProps = {
	isModalOpen: boolean;
	hideModal: () => void;
};
