import {
	BaselineCmdsInfo,
	useBaselineCmds,
} from 'features/VehicleProjects/baselines/baselineCmds';
import {ProjectDetailsPageParams} from 'features/VehicleProjects/vehicleProjectPagePaths';
import {
	TooltipTranslationOptions,
	createPathWithTooltipsTextKey,
	createTooltipTranslationProviderFromNamespace,
} from 'features/localizedTooltips';
import React, {useMemo} from 'react';
import {useParams} from 'react-router-dom';
import {BaselineCreationSuccessDialog} from '../components/BaselineCreationSuccessDialog';
import {LoadWrapper} from 'components/LoadWrapper';
import {
	translationNamespace,
	useBaselinesPageTranslations,
} from './BaselinesPage.utils';
import {BaselinesList} from './BaselinesList';
import {useGetBaselinesByModelAndSeriesQuery} from '../hooks/baseline.generated';
import {Breadcrumb, IBreadcrumbItem} from '@fluentui/react';
import {useGenerationBreadcrumbs} from '../baselines/breadcrumbs.utils';

const TranslationProvider =
	createTooltipTranslationProviderFromNamespace(translationNamespace);

const emptyArray: never[] = [];

export const useBreadcrumbsOfBaselinesPage = (
	params: ProjectDetailsPageParams,
	pageBreadcrumbFields?: Partial<IBreadcrumbItem>,
): IBreadcrumbItem[] => {
	const {t} = useBaselinesPageTranslations();

	const extraItems: IBreadcrumbItem[] = useMemo(() => {
		const createPageBreadcrumb = (): IBreadcrumbItem => {
			return {
				key: 'baselines',
				text: t('BreadcrumbText'),
				...pageBreadcrumbFields,
			};
		};

		return [createPageBreadcrumb()];
	}, [t, pageBreadcrumbFields]);

	return useGenerationBreadcrumbs({params, extraItems});
};

export function BaselinesPage(): JSX.Element {
	const params = useParams() as ProjectDetailsPageParams;
	const breadcrumbs: IBreadcrumbItem[] = useBreadcrumbsOfBaselinesPage(params);
	const {data, loading} = useGetBaselinesByModelAndSeriesQuery({
		variables: params,
	});

	const baselineList =
		data?.vehicleProjectBaselinesByModelAndSeries ?? emptyArray;

	const {creationResponse, infoForIsSuccessDialogOpen}: BaselineCmdsInfo =
		useBaselineCmds({params, showOverviewCmd: false});

	const translationHookOptions: TooltipTranslationOptions = useMemo(() => {
		return {keyPrefix: createPathWithTooltipsTextKey('Columns')};
	}, []);

	return (
		<>
			<Breadcrumb items={breadcrumbs} />
			<LoadWrapper loading={loading}>
				<TranslationProvider translationHookOptions={translationHookOptions}>
					<BaselinesList baselines={baselineList} params={params} />
				</TranslationProvider>
			</LoadWrapper>
			<BaselineCreationSuccessDialog
				creationResponse={creationResponse}
				infoForIsOpen={infoForIsSuccessDialogOpen}
			/>
		</>
	);
}
