import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type QuickSearchQueryVariables = Types.Exact<{
  searchString: Types.Scalars['String'];
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type QuickSearchQuery = { __typename?: 'Query', fullTextSearch: { __typename?: 'FullTextSearchResult', regulatoryDocuments: Array<{ __typename?: 'RegulatoryDocument', id: string, name: string, regulation?: { __typename?: 'Regulation', id: string, regulationNumber: string, name: string } | null }>, regulations: Array<{ __typename?: 'Regulation', id: string, regulationNumber: string, name: string }>, paragraphs: Array<{ __typename?: 'RegulatoryDocumentParagraph', id: string, enumeration: string, elements: Array<{ __typename?: 'RegulatoryDocumentParagraphElement', type: Types.ParagraphElementType, text?: string | null }>, parent: { __typename?: 'RegulatoryDocument', id: string, name: string, regulation?: { __typename?: 'Regulation', id: string, regulationNumber: string, name: string } | null } }> } };


export const QuickSearchDocument = gql`
    query QuickSearch($searchString: String!, $limit: Int) {
  fullTextSearch(searchString: $searchString, limit: $limit) {
    regulatoryDocuments {
      id
      name
      regulation {
        id
        regulationNumber
        name
      }
    }
    regulations {
      id
      regulationNumber
      name
    }
    paragraphs {
      id
      enumeration
      elements {
        type
        text
      }
      parent {
        id
        regulation {
          id
          regulationNumber
          name
        }
        name
      }
    }
  }
}
    `;

/**
 * __useQuickSearchQuery__
 *
 * To run a query within a React component, call `useQuickSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuickSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuickSearchQuery({
 *   variables: {
 *      searchString: // value for 'searchString'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useQuickSearchQuery(baseOptions: Apollo.QueryHookOptions<QuickSearchQuery, QuickSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuickSearchQuery, QuickSearchQueryVariables>(QuickSearchDocument, options);
      }
export function useQuickSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuickSearchQuery, QuickSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuickSearchQuery, QuickSearchQueryVariables>(QuickSearchDocument, options);
        }
export type QuickSearchQueryHookResult = ReturnType<typeof useQuickSearchQuery>;
export type QuickSearchLazyQueryHookResult = ReturnType<typeof useQuickSearchLazyQuery>;
export type QuickSearchQueryResult = Apollo.QueryResult<QuickSearchQuery, QuickSearchQueryVariables>;