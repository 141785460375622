import {
	Regulation,
	RegulatoryDocumentParagraph,
	Requirement,
	Simulation,
	VehicleProjectDetailsPageEntity,
} from 'types';
import {TFunction} from 'react-i18next';
import {formatDateTime} from 'i18n/localeDateFormat';
import i18n from 'i18n/i18n';
import {
	IHTMLXL,
	renderHeaderXL,
	renderHTMLExcel,
	cleanHTMLExcel,
	createZIPXL,
} from './ExcelExportCommon';
import Excel, {Worksheet} from 'exceljs';

interface ISimulationDetailPage {
	(entityListItems: any[], pageDetails: any, t: TFunction): Promise<void>;
}

interface IRenderHeaderSimulation {
	(
		sheet: Worksheet,
		vehicleProjectDetailsPage: VehicleProjectDetailsPageEntity,
		t: TFunction,
	): void;
}
export const handleClickSimulationDetail: ISimulationDetailPage = async (
	entityListItems,
	pageDetails,
	t,
) => {
	const vehicleProjectDetailsPage =
		pageDetails as VehicleProjectDetailsPageEntity;

	const arrImagesUri: string[] = [];
	const arrImagesName: string[] = [];
	const arrTables: string[] = [];

	const workbook = new Excel.Workbook();
	let arrColWidth = [30, 40, 80, 30, 20, 20, 20, 20, 20, 20];
	let strPageName = t('RequirementsHeader', {ns: 'features/requirements'});
	let sheet = workbook.addWorksheet('TRACE_' + strPageName);

	renderHeaderXL(workbook, sheet, arrColWidth, strPageName, t);
	renderHeaderSimulationDetails(sheet, vehicleProjectDetailsPage, t);

	let intRowCount = 7;
	let row = sheet.getRow(intRowCount);
	row.alignment = {wrapText: true, vertical: 'top'};
	row.font = {
		bold: true,
		name: 'Porsche Next TT',
		size: 10,
		color: {argb: 'FFFFFFFF'},
	};

	sheet.addTable({
		name: 'tableRequirements',
		ref: 'B' + String(intRowCount),
		style: {
			showRowStripes: true,
		},
		columns: [
			{
				name: t('RequirementId', {ns: 'features/requirements'}),
				filterButton: false,
			},
			{name: t('Name', {ns: 'features/requirements'}), filterButton: false},
			{
				name: t('Definition', {ns: 'features/requirements'}),
				filterButton: false,
			},
			{
				name: t('VexCluster', {ns: 'features/requirements'}),
				filterButton: false,
			},
			{name: t('Status', {ns: 'features/requirements'}), filterButton: false},
			{
				name: t('SystemLevels', {ns: 'features/requirements'}),
				filterButton: false,
			},
			{name: t('Markets', {ns: 'features/requirements'}), filterButton: false},
			{name: t('Keywords', {ns: 'features/requirements'}), filterButton: false},
			{name: t('Tags', {ns: 'features/requirements'}), filterButton: false},
			{name: t('Version', {ns: 'features/requirements'}), filterButton: false},
		],
		rows: [],
	});
	const arrRequirementsFilt = (
		entityListItems[0].__typename === 'Requirement'
			? entityListItems
			: vehicleProjectDetailsPage.assignedRequirements.map(x => x.requirement)
	) as Requirement[];
	let table = sheet.getTable('tableRequirements');
	intRowCount++;
	const tempDivElement = document.createElement('div');
	let arrHTMLXL: IHTMLXL[];
	let arrHTMLXLClean: IHTMLXL[];
	for (let ii = 0; ii < arrRequirementsFilt.length; ii++) {
		arrHTMLXL = [];
		arrHTMLXLClean = [];
		tempDivElement.innerHTML = arrRequirementsFilt[ii].definition
			? '<div>' + arrRequirementsFilt[ii].definition + '</div>'
			: '<div></div>';
		renderHTMLExcel(tempDivElement.childNodes[0].childNodes, arrHTMLXL);
		cleanHTMLExcel(arrHTMLXL, arrHTMLXLClean);

		row = sheet.getRow(intRowCount);
		row.alignment = {wrapText: true, vertical: 'top'};
		table.addRow([
			arrRequirementsFilt[ii].requirementId,

			arrRequirementsFilt[ii].name,

			{richText: arrHTMLXLClean, wrapText: true, vertical: 'top'},

			arrRequirementsFilt[ii].vexClusters
				.map(cluster => cluster.name)
				.join(';\n'),

			arrRequirementsFilt[ii].status
				? t(arrRequirementsFilt[ii].status, {
						ns: 'common/enums',
						keyPrefix: 'RequirementStatus',
				  })
				: '',

			arrRequirementsFilt[ii].systemLevels.map(level => level.name).join(';\n'),

			arrRequirementsFilt[ii].markets.map(market => market.name).join(';\n'),

			arrRequirementsFilt[ii].keywords.map(keyword => keyword.name).join(';\n'),

			arrRequirementsFilt[ii].tags.map(tags => tags.name).join(';\n'),

			arrRequirementsFilt[ii].version ?? '',
		]);

		intRowCount++;

		for (
			let jj = 0;
			jj < tempDivElement.getElementsByTagName('img').length;
			jj++
		) {
			arrImagesUri.push(
				tempDivElement
					.getElementsByTagName('img')[0]
					.getAttribute('src') as string,
			);
			arrImagesName.push(
				(arrRequirementsFilt[ii].requirementId as string) + '_' + jj + '.png',
			);
			row = sheet.getRow(intRowCount);
			row.outlineLevel = 1;
			row.getCell('B').fill = {
				type: 'pattern',
				pattern: 'solid',
				fgColor: {argb: '8DB4E2'},
			};
			row.getCell('D').alignment = {
				indent: 1,
				wrapText: true,
				vertical: 'top',
			};
			row.getCell('D').style.font = {
				underline: true,
				bold: false,
				color: {argb: '00B0F0'},
			};
			table.addRow([
				'',
				'',
				{
					text: t('LinkImage', {ns: 'components/EntityList/ExcelExport'}),
					hyperlink:
						(('assets/' + arrRequirementsFilt[ii].requirementId) as string) +
						'_' +
						jj +
						'.png',
				},
				'',
				'',
				'',
				'',
				'',
				'',
				'',
			]);
			intRowCount++;
		}
	}

	table.commit();

	arrColWidth = [30, 40, 80, 40, 40, 40];
	strPageName = t('Regulations', {
		ns: 'features/regulations',
		keyPrefix: 'RegulationsList',
	});
	sheet = workbook.addWorksheet('TRACE_' + strPageName);

	renderHeaderXL(workbook, sheet, arrColWidth, strPageName, t);
	renderHeaderSimulationDetails(sheet, vehicleProjectDetailsPage, t);

	const arrRegulations =
		vehicleProjectDetailsPage.regulations ?? ([] as Regulation[]);
	intRowCount = 7;
	row = sheet.getRow(intRowCount);
	row.alignment = {wrapText: true, vertical: 'top'};
	row.font = {
		bold: true,
		name: 'Porsche Next TT',
		size: 10,
		color: {argb: 'FFFFFFFF'},
	};
	sheet.addTable({
		name: 'tableRegulations',
		ref: 'B' + String(intRowCount),
		style: {
			showRowStripes: true,
		},
		columns: [
			{
				name: t('RegulationNumber', {
					ns: 'features/regulations',
					keyPrefix: 'RegulationsList',
				}),
				filterButton: false,
			},
			{
				name: t('Name', {
					ns: 'features/regulations',
					keyPrefix: 'RegulationsList',
				}),
				filterButton: false,
			},
			{
				name: t('Markets', {
					ns: 'features/regulations',
					keyPrefix: 'RegulationsList',
				}),
				filterButton: true,
			},
			{
				name: t('MainKeywords', {
					ns: 'features/regulations',
					keyPrefix: 'RegulationsList',
				}),
				filterButton: true,
			},
			{
				name: t('KeyWords', {
					ns: 'features/regulations',
					keyPrefix: 'RegulationsList',
				}),
				filterButton: true,
			},
			{
				name: t('StandardPlusPcmsClusters', {
					ns: 'features/regulations',
					keyPrefix: 'RegulationsList',
				}),
				filterButton: true,
			},
		],
		rows: [],
	});

	table = sheet.getTable('tableRegulations');

	intRowCount++;
	for (let ii = 0; ii < arrRegulations.length; ii++) {
		row = sheet.getRow(intRowCount);
		row.alignment = {wrapText: true, vertical: 'top'};
		table.addRow([
			arrRegulations[ii].regulationNumber,
			arrRegulations[ii].name,
			arrRegulations[ii].markets.map(market => market.name).join(';\n'),
			arrRegulations[ii].mainKeywords.map(keyword => keyword.name).join(';\n'),
			arrRegulations[ii].keywords.map(keyword => keyword.name).join(';\n'),
			arrRegulations[ii].standardPlusPcmsClusters
				.map(PCMS => PCMS.name)
				.join(';\n'),
		]);
		intRowCount++;
	}

	table.commit();

	arrColWidth = [
		30, 40, 80, 20, 20, 100, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20,
	];
	strPageName = t('ParagraphsHeader', {
		ns: 'features/regulatorydocuments',
		keyPrefix: 'RegulatoryDocumentDetailsPage',
	});
	sheet = workbook.addWorksheet('TRACE_' + strPageName);

	renderHeaderXL(workbook, sheet, arrColWidth, strPageName, t);
	renderHeaderSimulationDetails(sheet, vehicleProjectDetailsPage, t);

	const arrPara = (vehicleProjectDetailsPage.paragraphs ??
		[]) as unknown as RegulatoryDocumentParagraph[];
	intRowCount = 7;
	sheet.addTable({
		name: 'tableSegments',
		ref: 'B' + String(intRowCount),
		style: {
			showRowStripes: true,
		},
		columns: [
			{
				name: t('Regulation', {
					ns: 'features/regulatorydocuments',
					keyPrefix: 'ParagraphsList',
				}),
				filterButton: true,
			},
			{
				name: t('Version', {
					ns: 'features/regulatorydocuments',
					keyPrefix: 'ParagraphsList',
				}),
				filterButton: true,
			},
			{
				name: t('Paragraph', {
					ns: 'features/regulatorydocuments',
					keyPrefix: 'ParagraphsList',
				}),
				filterButton: false,
			},
			{
				name: t('Categories', {
					ns: 'features/regulatorydocuments',
					keyPrefix: 'ParagraphsList',
				}),
				filterButton: true,
			},
			{
				name: t('Keywords', {
					ns: 'features/regulatorydocuments',
					keyPrefix: 'ParagraphsList',
				}),
				filterButton: true,
			},
			{
				name: t('Requirements', {
					ns: 'features/regulatorydocuments',
					keyPrefix: 'ParagraphsList',
				}),
				filterButton: true,
			},
			{
				name: t('Tags', {
					ns: 'features/regulatorydocuments',
					keyPrefix: 'ParagraphsList',
				}),
				filterButton: true,
			},
			{
				name: t('VehicleCategory', {
					ns: 'features/regulatorydocuments',
					keyPrefix: 'ParagraphsList',
				}),
				filterButton: true,
			},
			{
				name: t('DriveVariants', {
					ns: 'features/regulatorydocuments',
					keyPrefix: 'ParagraphsList',
				}),
				filterButton: true,
			},
			{
				name: t('DateNewTypes', {
					ns: 'features/regulatorydocuments',
					keyPrefix: 'ParagraphsList',
				}),
				filterButton: true,
			},
			{
				name: t('DateNewRegistration', {
					ns: 'features/regulatorydocuments',
					keyPrefix: 'ParagraphsList',
				}),
				filterButton: true,
			},
			{
				name: t('Comprehensive', {
					ns: 'features/regulatorydocuments',
					keyPrefix: 'ParagraphsList',
				}),
				filterButton: true,
			},
			{
				name: t('ModelYear', {
					ns: 'features/regulatorydocuments',
					keyPrefix: 'ParagraphsList',
				}),
				filterButton: true,
			},
			{
				name: t('PhaseIn', {
					ns: 'features/regulatorydocuments',
					keyPrefix: 'ParagraphsList',
				}),
				filterButton: true,
			},
			{
				name: t('PhaseOut', {
					ns: 'features/regulatorydocuments',
					keyPrefix: 'ParagraphsList',
				}),
				filterButton: true,
			},
		],
		rows: [],
	});

	row = sheet.getRow(intRowCount);
	row.font = {
		bold: true,
		name: 'Porsche Next TT',
		size: 10,
		color: {argb: 'FFFFFFFF'},
	};
	table = sheet.getTable('tableSegments');

	let arrRichTexts = [];
	let arrRichTextsReq = [];

	let intTableCount = 0;

	let regulationNumber = '';
	let regulationVerion = '';
	let category = '';
	let keyword = '';
	let tag = '';
	let vehClass = '';
	let drivetrain = '';
	let dateNT = '';
	let dateNF = '';
	let dateUB = '';
	let modelyear = '';
	let phaseIn = '';
	let phaseOut = '';

	intRowCount++;
	for (let ii = 0; ii < arrPara.length; ii++) {
		const arrRequirementsSorted = arrPara[ii].requirements.slice();
		arrRequirementsSorted.sort(function (a, b) {
			return a.requirementId! < b.requirementId! ? -1 : 1;
		});
		arrRichTexts = [];
		arrRichTextsReq = [];
		regulationNumber = arrPara[ii].parent.regulation!.regulationNumber;
		regulationVerion = arrPara[ii].parent.name;

		category = arrPara[ii].categories
			.map(category => category.name)
			.join(';\n');

		keyword = arrPara[ii].keywords.map(keyword => keyword.name).join(';\n');

		tag = arrPara[ii].tags.map(tag => tag.name).join(';\n');

		vehClass = arrPara[ii].vehicleCategories
			.map(category => category.name)
			.join(';\n');

		drivetrain = arrPara[ii].driveVariants.map(drive => drive.name).join(';\n');

		dateNT = arrPara[ii].dateNewTypes
			? formatDateTime(new Date(arrPara[ii].dateNewTypes), i18n)
			: '';

		dateNF = arrPara[ii].dateNewRegistration
			? formatDateTime(new Date(arrPara[ii].dateNewRegistration), i18n)
			: '';

		dateUB = arrPara[ii].comprehensive
			? formatDateTime(new Date(arrPara[ii].comprehensive), i18n)
			: '';

		modelyear = String(arrPara[ii].modelYear ?? '');

		phaseIn = (arrPara[ii].phaseIn ?? [])
			.map(
				PI =>
					(PI.date ? formatDateTime(new Date(PI.date), i18n) : '') +
					' (' +
					(PI.status ?? '') +
					')',
			)
			.join(';\n');

		phaseOut = (arrPara[ii].phaseOut ?? [])
			.map(
				PI =>
					(PI.date ? formatDateTime(new Date(PI.date), i18n) : '') +
					' (' +
					(PI.status ?? '') +
					')',
			)
			.join(';\n');

		for (let jj = 0; jj < arrRequirementsSorted.length; jj++) {
			arrRichTextsReq.push({
				font: {bold: true, name: 'Porsche Next TT', size: 10},
				text:
					arrRequirementsSorted[jj].name +
					' (' +
					arrRequirementsSorted[jj].requirementId +
					')\n\n',
			});
		}

		for (let jj = 0; jj < arrPara[ii].elements.length; jj++) {
			if (String(arrPara[ii].elements[jj].type) === 'TEXT') {
				if (arrPara[ii].elements[jj].isHeader === true) {
					arrRichTexts.push({
						font: {bold: true, name: 'Porsche Next TT', size: 10},
						text: arrPara[ii].elements[jj].text + '\n',
					});
				} else {
					arrRichTexts.push({
						font: {bold: false, name: 'Porsche Next TT', size: 10},
						text: arrPara[ii].elements[jj].text + '\n',
					});
				}
			}

			if (String(arrPara[ii].elements[jj].type) === 'NEW_LINE') {
				arrRichTexts.push({text: '\n'});
			}

			if (jj === arrPara[ii].elements.length - 1) {
				row = sheet.getRow(intRowCount);
				row.alignment = {wrapText: true, vertical: 'top'};
				row.alignment = {wrapText: true, vertical: 'top'};
				table.addRow([
					regulationNumber,
					regulationVerion,
					{richText: arrRichTexts, wrapText: true, vertical: 'top'},
					category,
					keyword,
					{richText: arrRichTextsReq, wrapText: true, vertical: 'top'},
					tag,
					vehClass,
					drivetrain,
					dateNT,
					dateNF,
					dateUB,
					modelyear,
					phaseIn,
					phaseOut,
				]);
				intRowCount++;
				arrRichTexts = [];
			}
		}

		const arrLinks: string[] = [];
		const arrLinkText: string[] = [];

		for (let jj = 0; jj < arrPara[ii].elements.length; jj++) {
			if (
				String(arrPara[ii].elements[jj].type) === 'IMAGE' ||
				String(arrPara[ii].elements[jj].type) === 'TABLE'
			) {
				arrImagesUri.push(String(arrPara[ii].elements[jj].asset?.uri));
				arrImagesName.push(String(arrPara[ii].elements[jj].text));
				arrLinks.push('assets/' + arrImagesName[arrImagesName.length - 1]);
				arrLinkText.push(
					t('LinkImage', {ns: 'components/EntityList/ExcelExport'}),
				);
			}

			if (String(arrPara[ii].elements[jj].type) === 'HTML_TABLE') {
				arrTables.push(String(arrPara[ii].elements[jj].text));
				arrLinks.push('assets/html_table_' + intTableCount + '.html');
				arrLinkText.push(
					t('LinkTable', {ns: 'components/EntityList/ExcelExport'}),
				);
				intTableCount++;
			}
		}

		let intRowCountReq = 0;
		let arrHTMLXL: IHTMLXL[];
		let arrHTMLXLClean: IHTMLXL[];
		for (let jj = 0; jj < arrRequirementsSorted.length; jj++) {
			arrHTMLXL = [];
			arrHTMLXLClean = [
				{
					font: {bold: true, name: 'Porsche Next TT', size: 10},
					text:
						arrRequirementsSorted[jj].name +
						' (' +
						arrRequirementsSorted[jj].requirementId +
						')\n',
				},
			];
			tempDivElement.innerHTML = arrRequirementsSorted[jj].definition
				? '<div>' + arrRequirementsSorted[jj].definition + '</div>'
				: '<div></div>';
			renderHTMLExcel(tempDivElement.childNodes[0].childNodes, arrHTMLXL);
			cleanHTMLExcel(arrHTMLXL, arrHTMLXLClean);

			for (
				let jj = 0;
				jj < tempDivElement.getElementsByTagName('img').length;
				jj++
			) {
				if (
					arrImagesName.indexOf(
						(arrRequirementsSorted[jj].requirementId as string) +
							'_' +
							jj +
							'.png',
					) === -1
				) {
					arrImagesUri.push(
						tempDivElement
							.getElementsByTagName('img')[0]
							.getAttribute('src') as string,
					);
					arrImagesName.push(
						(arrRequirementsSorted[jj].requirementId as string) +
							'_' +
							jj +
							'.png',
					);
				}
			}

			row = sheet.getRow(intRowCount);
			row.outlineLevel = 1;
			row.alignment = {wrapText: true, vertical: 'top'};
			row.getCell('B').fill = {
				type: 'pattern',
				pattern: 'solid',
				fgColor: {argb: '8DB4E2'},
			};
			row.getCell('D').style.font = {
				underline: true,
				bold: false,
				color: {argb: '00B0F0'},
			};
			row.getCell('G').alignment = {
				indent: 1,
				wrapText: true,
				vertical: 'top',
			};
			table.addRow([
				'',
				'',
				intRowCountReq < arrLinks.length
					? {
							text: arrLinkText[intRowCountReq],
							hyperlink: arrLinks[intRowCountReq],
					  }
					: '',
				'',
				'',
				{richText: arrHTMLXLClean, wrapText: true, vertical: 'top'},
				'',
				'',
				'',
				'',
				'',
				'',
				'',
				'',
				'',
			]);
			intRowCount++;
			intRowCountReq++;

			for (
				let kk = 0;
				kk < tempDivElement.getElementsByTagName('img').length;
				kk++
			) {
				row = sheet.getRow(intRowCount);
				row.outlineLevel = 1;
				row.alignment = {wrapText: true, vertical: 'top'};
				row.getCell('B').fill = {
					type: 'pattern',
					pattern: 'solid',
					fgColor: {argb: '8DB4E2'},
				};
				row.getCell('D').style.font = {
					underline: true,
					bold: false,
					color: {argb: '00B0F0'},
				};
				row.getCell('G').alignment = {
					indent: 1,
					wrapText: true,
					vertical: 'top',
				};
				row.getCell('G').style.font = {
					underline: true,
					bold: false,
					color: {argb: '00B0F0'},
				};
				table.addRow([
					'',
					'',
					intRowCountReq < arrLinks.length
						? {
								text: arrLinkText[intRowCountReq],
								hyperlink: arrLinks[intRowCountReq],
						  }
						: '',
					'',
					'',
					{
						text: t('LinkImage', {ns: 'components/EntityList/ExcelExport'}),
						hyperlink:
							(('assets/' +
								arrRequirementsSorted[jj].requirementId) as string) +
							'_' +
							kk +
							'.png',
					},
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
				]);
				intRowCount++;
				intRowCountReq++;
			}
		}

		for (let jj = intRowCountReq; jj < arrLinks.length; jj++) {
			row = sheet.getRow(intRowCount);
			row.outlineLevel = 1;
			row.alignment = {wrapText: true, vertical: 'top'};
			row.getCell('B').fill = {
				type: 'pattern',
				pattern: 'solid',
				fgColor: {argb: '8DB4E2'},
			};
			row.getCell('D').style.font = {
				underline: true,
				bold: false,
				color: {argb: '00B0F0'},
			};
			table.addRow([
				'',
				'',
				{text: arrLinkText[jj], hyperlink: arrLinks[jj]},
				'',
				'',
				'',
				'',
				'',
				'',
				'',
				'',
				'',
				'',
				'',
				'',
			]);
			intRowCount++;
		}
	}

	table.commit();

	const strZipName =
		t('PageTitle', {ns: 'features/Simulations/simulationsPage'}) +
		'_' +
		(vehicleProjectDetailsPage as unknown as Simulation).name;
	await createZIPXL(
		workbook,
		strZipName,
		t,
		arrTables,
		arrImagesUri,
		arrImagesName,
	);
};

const renderHeaderSimulationDetails: IRenderHeaderSimulation = (
	sheet,
	vehicleProjectDetailsPage,
	t,
) => {
	const simulation = vehicleProjectDetailsPage as unknown as Simulation;
	let intRowCount = 4;
	let row = sheet.getRow(intRowCount);
	row.alignment = {wrapText: true, vertical: 'top'};

	row.getCell('B').value = {
		richText: [
			{
				font: {bold: true, name: 'Porsche Next TT', size: 10},
				text: t('Generation', {ns: 'features/vehicleprojects'}) + '\n',
			},
			{
				font: {bold: false, name: 'Porsche Next TT', size: 10},
				text: simulation.generationReference ?? '',
			},
		],
	};
	row.getCell('C').value = {
		richText: [
			{
				font: {bold: true, name: 'Porsche Next TT', size: 10},
				text: t('ModelSeries', {ns: 'features/vehicleprojects'}) + '\n',
			},
			{
				font: {bold: false, name: 'Porsche Next TT', size: 10},
				text: simulation.modelSeriesReference ?? '',
			},
		],
	};
	row.getCell('D').value = {
		richText: [
			{
				font: {bold: true, name: 'Porsche Next TT', size: 10},
				text: t('Name', {ns: 'features/vehicleprojects'}) + '\n',
			},
			{
				font: {bold: false, name: 'Porsche Next TT', size: 10},
				text: simulation.name,
			},
		],
	};

	intRowCount++;
	row = sheet.getRow(intRowCount);
	row.alignment = {wrapText: true, vertical: 'top'};
	row.getCell('B').value = {
		richText: [
			{
				font: {bold: true, name: 'Porsche Next TT', size: 10},
				text: t('SalesMarkets', {ns: 'features/vehicleprojects'}) + '\n',
			},
			{
				font: {bold: false, name: 'Porsche Next TT', size: 10},
				text: simulation.markets.map(x => x.name).join(';\n'),
			},
		],
	};
	row.getCell('C').value = {
		richText: [
			{
				font: {bold: true, name: 'Porsche Next TT', size: 10},
				text: t('Derivative', {ns: 'features/vehicleprojects'}) + '\n',
			},
			{
				font: {bold: false, name: 'Porsche Next TT', size: 10},
				text: simulation.derivatives.join(';\n'),
			},
		],
	};
	row.getCell('D').value = {
		richText: [
			{
				font: {bold: true, name: 'Porsche Next TT', size: 10},
				text: t('EarliestSOP', {ns: 'features/vehicleprojects'}) + '\n',
			},
			{
				font: {bold: false, name: 'Porsche Next TT', size: 10},
				text: simulation.earliestSOP
					? formatDateTime(new Date(simulation.earliestSOP), i18n)
					: '',
			},
		],
	};
};
