import React, {useCallback, useMemo} from 'react';

import {FetchResult} from '@apollo/client';
import {IButtonStyles, IIconProps, IStyle, IconButton} from '@fluentui/react';
import {useBaselinesPageTranslations} from './BaselinesPage.utils';
import {
	ExportBaselineRequirementsMutation,
	useExportBaselineRequirementsMutation,
} from '../hooks/baseline.generated';
import {BaselineOfBaselinesPage} from './BaselinesPage.types';

interface Props {
	baseline: BaselineOfBaselinesPage;
}

export function DownloadBtn({baseline}: Props): JSX.Element {
	const [exportBaselineRequirements] = useExportBaselineRequirementsMutation();
	const {t} = useBaselinesPageTranslations();

	const exportBaselineRequirementsAndDownloadFile =
		useCallback(async (): Promise<void> => {
			const downloadFile = (url: string) => {
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', '');
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			};

			type ExportResult = FetchResult<ExportBaselineRequirementsMutation>;

			const createRequirementIdsAndExportRequirements =
				(): Promise<ExportResult> => {
					return exportBaselineRequirements({
						variables: {
							input: {baselineId: baseline.id, onlyRequirementsIds: []},
						},
					});
				};

			const response: ExportResult =
				await createRequirementIdsAndExportRequirements();
			const url = response.data?.exportBaselineRequirements.blobRef?.uri;
			if (url) downloadFile(url);
		}, [baseline, exportBaselineRequirements]);

	const iconProps = useMemo((): IIconProps => {
		return {iconName: 'Download'};
	}, []);

	const styles = useMemo((): IButtonStyles => {
		const bgColorStyles: IStyle = {backgroundColor: 'transparent'};
		return {
			root: {height: '1rem'},
			rootHovered: bgColorStyles,
			rootPressed: bgColorStyles,
		};
	}, []);

	return (
		<IconButton
			iconProps={iconProps}
			onClick={exportBaselineRequirementsAndDownloadFile}
			title={t('DownloadBtnTitle')}
			styles={styles}
		/>
	);
}
