import {useTranslation} from 'react-i18next';
import {
	useUpdateRegulatoryDocumentParagraphsMutation,
	GetCompareDocumentDataQuery,
} from '../../../hooks';
import {useUserContext} from 'authentication/UserContext';
import {GetRegulationDocument} from 'features/Regulations/hooks';

import {mapToRef} from 'helpers';
import {IComparedItem} from '../compareDocuments/compareDocuments';
import {RegulatoryDocumentParagraph, ParagraphUpdateInput} from 'types';
import {GetRegulatoryDocumentDetailsDocument} from 'features/RegulatoryDocuments/hooks/useGetRegulatoryDocumentDetails.generated';
import {useAssignRegulatoryDocumentParagraphsRequirementsDocumentCompareMutation} from 'features/RegulatoryDocuments/hooks/useAssignRegulatoryDocumentParagraphsRequirementsDocumentCompare.generated';

export const useComparisonUpdate = (
	selectedItems: IComparedItem[],
	newVersion: NonNullable<GetCompareDocumentDataQuery['regulatoryDocument']>,
) => {
	const {t} = useTranslation('features/regulatorydocuments', {
		keyPrefix: 'CompareVersionPanel',
	});
	const {isVex} = useUserContext();

	const [updateParagraphs] = useUpdateRegulatoryDocumentParagraphsMutation();
	const [updateParagraphsRequirementsCompareDocument] =
		useAssignRegulatoryDocumentParagraphsRequirementsDocumentCompareMutation();

	// All update functionallity for VEX
	const vexUpdateHandler = () => {
		updateParagraphs({
			variables: {
				input: {
					regulatoryDocumentId: newVersion.id,
					paragraphUpdates: selectedItems.reduce((result, item) => {
						const {oldParagraph, newParagraph} = item;
						if (oldParagraph && newParagraph) {
							const values =
								oldParagraph.paragraph as unknown as RegulatoryDocumentParagraph;
							const defaultValues =
								newParagraph.paragraph as unknown as RegulatoryDocumentParagraph;
							return result.concat({
								paragraphId: newParagraph.paragraph.id,
								update: {
									vehicleCategoryRefs: mapToRef(
										defaultValues.vehicleCategories,
									),
									keywordRefs: mapToRef(defaultValues.keywords),
									driveVariantRefs: mapToRef(defaultValues.driveVariants),
									categoryRefs: mapToRef(defaultValues.categories),
									// Update only tagRefs here (by using values)
									tagRefs: mapToRef(values.tags),
								},
							});
						}

						return result;
					}, [] as ParagraphUpdateInput[]),
				},
			},
			refetchQueries: [GetRegulationDocument],
		});
		updateParagraphsRequirementsCompareDocument({
			variables: {
				input: {
					regulatoryDocumentId: newVersion.id,
					requirementRefs: selectedItems.map(item => {
						const {oldParagraph} = item;
						const requirementIds = mapToRef(
							oldParagraph?.paragraph.requirements,
						);
						return requirementIds;
					}),
					paragraphIds: selectedItems.map(item => {
						const {newParagraph} = item;

						return newParagraph?.paragraph.id;
					}),
				},
			},
			refetchQueries: [GetRegulatoryDocumentDetailsDocument],
		});
	};

	const vexUpdatedTextItems = [t('Tags'), t('Requirements')];

	// All update functionallity for every other role
	const miscUpdateHandler = () =>
		updateParagraphs({
			variables: {
				input: {
					regulatoryDocumentId: newVersion.id,
					paragraphUpdates: selectedItems.reduce((result, item) => {
						const {oldParagraph, newParagraph} = item;
						if (oldParagraph && newParagraph) {
							const values =
								oldParagraph.paragraph as unknown as RegulatoryDocumentParagraph;
							const defaultValues =
								newParagraph.paragraph as unknown as RegulatoryDocumentParagraph;
							return result.concat({
								paragraphId: newParagraph.paragraph.id,
								update: {
									vehicleCategoryRefs: mapToRef(values.vehicleCategories),
									keywordRefs: mapToRef(values.keywords),
									driveVariantRefs: mapToRef(values.driveVariants),
									categoryRefs: mapToRef(values.categories),
									// Do not update tagRefs here (by using defaultValues)
									tagRefs: mapToRef(defaultValues.tags),
								},
							});
						}

						return result;
					}, [] as ParagraphUpdateInput[]),
				},
			},
			refetchQueries: [GetRegulationDocument],
		});
	const miscUpdatedTextItems = [
		t('VehicleCategories'),
		t('Keywords'),
		t('Categories'),
		t('DriveVariants'),
	];

	return {
		updateHandler: isVex ? vexUpdateHandler : miscUpdateHandler,
		updatedTextItems: isVex ? vexUpdatedTextItems : miscUpdatedTextItems,
	};
};
